import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { isUserAuthenticated } from "../helper/AuthUtils";

console.log("isUserAuthenticated", isUserAuthenticated());

// Protected if user not authenticated.. Redirect "/"
export const ProtectedRoute = ({ redirectPath = "/" }) => {
  if (!isUserAuthenticated()) {
    localStorage.removeItem("wallet");
    localStorage.removeItem("token");
    localStorage.removeItem("provider");
    localStorage.clear();
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

// Protected if User In A Game  Redirect only  "/start-game"
export const ProtectedRestrict = ({ redirectPath = "/start-game" }) => {
  const userInGame = localStorage.getItem("GameStarted");
  const token = localStorage.getItem("token");
  if (userInGame == "yes" && token != null) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

// Protected if Authenticated navigate "/home"
export const ProtectedAuth = ({ redirectPath = "/home" }) => {
  const token = localStorage.getItem("token");
  if (token != null) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

// Protected Not go StartGame if Game not started
export const ProtectedGame = ({ redirectPath = "/bet" }) => {
  const userInGame = localStorage.getItem("GameStarted");
  if (userInGame != "yes") {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};
