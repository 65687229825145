import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import "./SelectMode.css";
import { useNavigate } from "react-router-dom";
import InstructionsDrawer from "../Drawers/InstructionsDrawer";
import { useSelector } from "react-redux";
import { playBtnClickSound } from "../../Sounds/SoundController";
import Lottie from "lottie-react";
import noInternet from "../lottie-files/no-internet.json";

const SelectMode = () => {
  const isSoundOn = useSelector((state) => state.sound.isPlaying);

  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/home");
  };

  const goToBetPage = () => {
    navigate("/bet");
  };
  return (
    <>
      {/* <Navbar /> */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasInstruction"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <InstructionsDrawer />
        </div>
      </div>
      {isOnline ? (
        <div className="splash-main-wrapper">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space"> </div>
          <div className="mode-select-wrapper">
            <img
              onClick={goBack}
              className="goback-mode-btn"
              src="./assets/icons/back-white.png"
              alt="icon"
            />
            <div className="mode-select-wrap">
              <h2>
                MODE <br /> SELECTIONS
              </h2>

              <div
                className="select-mode-box"
                onClick={() => {
                  playBtnClickSound(isSoundOn);
                  goToBetPage();
                }}
              >
                <img src="./assets/icons/userAvtar.png" alt="icon" />
                <h3> VS </h3>
                <img src="./assets/icons/userAvtar.png" alt="icon" />
              </div>

              <div className="game-instructions-text">
                <h4
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasInstruction"
                  aria-controls="offcanvasRight"
                >
                  See Game Instructions ?
                </h4>
                <img
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasInstruction"
                  aria-controls="offcanvasRight"
                  src="./assets/icons/back-arrow.png"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectMode;
