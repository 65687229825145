import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import loseSound from "../../Sounds/lose_sound.wav";

const AndroidLoseModal = (props) => {
  const [audio] = useState(new Audio(loseSound));

  useEffect(() => {
    if (props.isLoseSound) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [props.isLoseSound, audio]);
  return (
    <>
      {props.show && <div className="blur-background"></div>}
      <Modal
        {...props}
        size="lg"
        className="lose-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="lose-modal-wrapper">
            <img src="../assets/icons/lose.png" alt="icon" />
            <h2>YOU LOSE</h2>
            <div className="lose-amount-wrap">
              <h4> 0.001 </h4>
              <img src="../assets/icons/eth-icon.png" alt="icon" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AndroidLoseModal;
