import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";

const TransferModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-main-wrapper">
            <img src="./assets/icons/confirm-tick.png" alt="icon" />
            <h3>
              Amount Successfully Transfered To <br />
              <span> RPS Betting Wallet </span>
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransferModal;
