import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./Splash.css";
import { useSelector } from "react-redux";
import { playBtnClickSound } from "../Sounds/SoundController";
import Lottie from "lottie-react";
import noInternet from "./lottie-files/no-internet.json";

const Home = () => {
  const [centerIndex, setCenterIndex] = useState(0);
  const navigate = useNavigate();
  const isSoundOn = useSelector((state) => state.sound.isPlaying);

  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const images = [
    { src: "./assets/icons/rock.png", alt: "Rock" },
    { src: "./assets/icons/hand.png", alt: "Hand" },
    { src: "./assets/icons/scissor.png", alt: "Scissor" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCenterIndex((centerIndex) => (centerIndex + 1) % images.length);
    }, 1900);

    return () => clearInterval(interval);
  }, []);

  const goToselectMode = () => {
    navigate("/mode");
  };

  return (
    <>
      {/* <Navbar /> */}
      {isOnline ? (
        <div className="splash-main-wrapper">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space"> </div>
          <div className="splash-main-wrap splash-connect-space">
            <div className="splace-heading">
              <h2>
                ROCK PAPER <br /> SCISSORS
              </h2>
            </div>

            <div className="splash-carousel">
              <div className="splash-carousel-content">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`splash-carousel-item ${
                      index === centerIndex ? "center-item" : ""
                    }`}
                    style={{
                      opacity: index === centerIndex ? 1 : 1,
                      transition: "opacity 0.3s ease-in-out",
                    }}
                  >
                    <img src={image.src} alt={image.alt} />
                  </div>
                ))}
              </div>
            </div>

            <img
              onClick={() => {
                playBtnClickSound(isSoundOn);
                goToselectMode();
              }}
              className="game-play-button"
              src="./assets/icons/play-btn.png"
              alt="icon"
            />
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
