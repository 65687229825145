import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import timerSound from "../../Sounds/timer_sound.wav";
import Lottie from "lottie-react";
import noInternet from "../lottie-files/no-internet.json";
import loader from "../lottie-files/loader.json";
import { servicePost } from "../../helper/api";
import { web3 } from "../../constants/constant";
const rsp = require("../../abis/rsp.json");
const WaitingOpponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpponentJoin, setIsOpponentJoin] = useState("");
  const [gameId, setGameId] = useState();
  const [isTimerSound, setIsTimerSound] = useState(false);
  const [audio] = useState(new Audio(timerSound));
  const [isOpponentFound, setIsOpponentFound] = useState(true);
  const isSoundOn = useSelector((state) => state.sound.isPlaying);
  const [seconds, setSeconds] = useState(55);
  const [color, setColor] = useState("");

  console.log("gameId", gameId);

  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const goToBetPage = () => {
    navigate("/bet");
    audio.pause();
  };

  const goToStartGame = () => {
    navigate("/start-game");
    audio.pause();
  };

  // on Timer Sound
  useEffect(() => {
    audio.loop = true; // Enable looping
    if (isTimerSound && pathname === "/opponent-waiting") {
      audio.currentTime = 0; // Reset the audio to the beginning
      if (isSoundOn) {
        audio.play().catch((error) => {
          console.log("Autoplay failed:", error);
        });
      }
    } else {
      audio.pause();
      audio.currentTime = 0; // Reset the audio to the beginning
    }

    return () => {
      audio.pause();
      audio.currentTime = 0; // Reset the audio to the beginning
    };
  }, [isTimerSound, pathname, isSoundOn]);

  // Count down
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setIsTimerSound(true);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      // Countdown reached 00, perform necessary action
      setIsOpponentFound(false);
      audio.pause();
    }
    if (seconds == 10) {
      setColor("yellow");
    }
    if (seconds == 5) {
      setColor("red");
    }
  }, [seconds]);

  const addComputerOpponent = async () => {
    try {
      const computerJoin = await servicePost("oppenent/addOppenent", {
        walletId: "0x821933A74439CA1FCc651d747c0F1aF24014a934",
        provider: "metamask",
        amount: "0.001",
      });
      console.log("createGame", computerJoin);
    } catch (error) {
      console.log("err", error);
    }
  };

  // Const Check Opponent Join Or Not
  const checkOpponetJoin = async () => {
    try {
      const provider = localStorage.getItem("wallet");
      const response = await servicePost("oppenent/getoppenent", {
        walletId: provider,
      });
      setIsOpponentJoin(response.data.isOppenentJoin);
      setGameId(response.data.gameId);
      if (isOpponentJoin == true) {
        //  var txhash;
        //  const ether = web3.utils.toWei("0.0001", "ether");
        //   const rspAbi=rsp.abi;
        //  // const contract = new web3.eth.Contract(rspAbi, "0x49b06c90d8ce2722dd196ca55e2df0a5738bd472");
        //  const contract = new web3.eth.Contract(rspAbi, "0x1a3fbDd6aa84f20C13029F7A1feB71a5e92Aa2D5");
        //   let startnewgame = await contract.methods.newGame(response.data.player2, ether).send({ from: response.data.player1,  })
        //   .then(async (val) => {
        //   //console.log("val=",val);
        //     txhash=val.transactionHash
        //     console.log("val.transactionHash=",val.transactionHash);
        //   });
        localStorage.setItem("GameStarted", "yes");
        goToStartGame();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkOpponetJoin();
    setTimeout(() => {
      // addComputerOpponent();
      localStorage.setItem("GameStarted", "yes");
      goToStartGame();
    }, 5000);
  }, [seconds]);

  const deleteGame = async () => {
    try {
      setIsLoading(true);
      const data = await servicePost("oppenent/deleteGame", {
        gameId: gameId,
      });
      setIsLoading(false);
      goToBetPage();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      {isOnline ? (
        <div className="splash-main-wrapper">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space"> </div>
          {isOpponentFound ? (
            <>
              <div className="opponent-waiting-wrapper">
                <div className="opponent-waiting-wrap">
                  <div className="opponent-wait-warn">
                    <p>
                      In the event of losing your internet connection, the bid
                      amount you placed will be forfeited.
                    </p>
                  </div>
                  <div className="timmer-main-wrap">
                    <img
                      // onClick={goToStartGame}
                      src="./assets/icons/timer.png"
                      alt="icon"
                    />

                    <div className="countdown-container">
                      <h2
                        className={
                          seconds === 0
                            ? `countdown-text ${color} finished`
                            : `countdown-text ${color}`
                        }
                      >
                        {seconds}
                      </h2>
                    </div>
                  </div>

                  <p> Waiting for Opponent... </p>

                  <div className="waiting-opponent-footer-text">
                    <img src="./assets/icons/white-shield.png" alt="icon" />
                    <p>
                      When Your Game Start, If you do not select a gesture
                      within <br />
                      10 seconds, you will lose your bet.
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="opoonent-not-found-wrapper">
              <div className="opoonent-not-found-wrap">
                <img src="./assets/icons/white-shield.png" alt="icon" />
                <p>
                  Apologies for the inconvenience. It may take a bit longer than
                  <br />
                  expected to find an opponent for you.
                </p>
                <div className="opponent-found-btn">
                  <button
                    onClick={() => {
                      // goToStartGame();
                      setIsOpponentFound(true);
                      setIsTimerSound(true);
                      setSeconds(45);
                      setColor("");
                    }}
                  >
                    Try Again
                  </button>
                  {isLoading ? (
                    <button disabled className="bid-cencel-btn">
                      <div className="tranfer-button-loading">
                        <Lottie animationData={loader} loop={true}></Lottie>
                      </div>
                    </button>
                  ) : (
                    <button onClick={deleteGame} className="bid-cencel-btn">
                      Cancel Bid
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WaitingOpponent;
