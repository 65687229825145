import React, { Fragment, useEffect, useState } from "react";
import { servicePost } from "../../helper/api";
import moment from "moment";
import { useSelector } from "react-redux";
import { web3 } from "../../constants/constant";
const TransferHistory = () => {
  const [allTransaction, setAllTransactions] = useState([]);

  const txDep = useSelector((state) => state.sound.txDep);

  const transactionHistory = async () => {
    const loggedUser = await web3.eth.getAccounts();
    // alert(loggedUser);
    try {
      const data = await servicePost("transaction/getTransaction", {
        walletId: loggedUser[0],
      });
      // console.log(data.data);
      setAllTransactions(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    transactionHistory();
  }, [txDep]);

  return (
    <>
      <div className="connect-wallet-wrapper">
        <div className="connect-wallet-wrap">
          <div className="connect-wallet-header">
            <h2>Transactions</h2>
            <img
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              src="./assets/icons/back-arrow.png"
              alt="icon"
            />
          </div>
          <div className="transactions-drawer-body">
            <div className="transation-main-wrap">
              {allTransaction.length === 0 ? (
                <div className="no-transactions-warp">
                  <h2>
                    No <br /> Transactions
                  </h2>
                </div>
              ) : (
                allTransaction &&
                allTransaction?.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="transation-history-repeat">
                        <div className="transation-repeat-left">
                          <div className="transation-icon-box">
                            {data.gameresult == "You Won" ? (
                              <>
                                <img
                                  className="t-bet-icon"
                                  src="./assets/icons/won.svg"
                                  alt="img"
                                />
                              </>
                            ) : data.gameresult == "You Lose" ? (
                              <img
                                className="t-bet-icon"
                                src="./assets/icons/bet.svg"
                                alt="img"
                              />
                            ) : data.transactionType == "Meta to RPS Wallet" ? (
                              <>
                                <img
                                  src="./assets/icons/metamask.png"
                                  alt="img"
                                />
                                <img
                                  className="won-transation"
                                  src="./assets/icons/won.svg"
                                  alt="icon"
                                />
                              </>
                            ) : data.transactionType == "RPS Wallet to Meta" ? (
                              <>
                                <img
                                  className="t-rps-icon"
                                  src="./assets/icons/rps-icon.png"
                                  alt="img"
                                />
                                <img
                                  className="won-transation"
                                  src="./assets/icons/send.svg"
                                  alt="icon"
                                />
                              </>
                            ) : data.transactionType == "Tie" ? (
                              <>
                                <img
                                  className="t-rps-icon"
                                  src="./assets/icons/rps-icon.png"
                                  alt="img"
                                />
                                <img
                                  className="won-transation"
                                  src="./assets/icons/bet.svg"
                                  alt="icon"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="transation-history-info">
                            <h3> {data.transactionType} </h3>
                            <p>{moment(data.createdOn).add().calendar()}</p>
                          </div>
                        </div>
                        <div className="transation-repeat-right">
                          <h3> {data.amount} </h3>
                          <img src="./assets/icons/eth-icon.png" alt="img" />
                        </div>
                      </div>
                      {index !== allTransaction.length - 1 && (
                        <img
                          className="t-line"
                          src="./assets/icons/t-line.png"
                          alt="line"
                        />
                      )}
                    </Fragment>
                  );
                })
              )}
              {/* <div className="transation-history-repeat">
                <div className="transation-repeat-left">
                  <div className="transation-icon-box">
                    <img src="./assets/icons/metamask.png" alt="img" />
                    <img
                      className="won-transation"
                      src="./assets/icons/won.svg"
                      alt="icon"
                    />
                  </div>

                  <div className="transation-history-info">
                    <h3> Meta to RPS Wallet </h3>
                    <p> Today, 10:00 am </p>
                  </div>
                </div>
                <div className="transation-repeat-right">
                  <h3> 0.003</h3>
                  <img src="./assets/icons/eth-icon.png" alt="img" />
                </div>
              </div>
              <img
                className="t-line"
                src="./assets/icons/t-line.png"
                alt="line"
              />
            </div>

            <div className="transactions-drawer-body">
              <div className="transation-main-wrap">
                <div className="transation-history-repeat">
                  <div className="transation-repeat-left">
                    <div className="transation-icon-box">
                      <img
                        className="t-rps-icon"
                        src="./assets/icons/rps-icon.png"
                        alt="img"
                      />
                      <img
                        className="won-transation"
                        src="./assets/icons/send.svg"
                        alt="icon"
                      />
                    </div>

                    <div className="transation-history-info">
                      <h3> RPS Wallet to Meta </h3>
                      <p> Today, 11:00 am </p>
                    </div>
                  </div>
                  <div className="transation-repeat-right">
                    <h3> 0.001</h3>
                    <img src="./assets/icons/eth-icon.png" alt="img" />
                  </div>
                </div>
                <img
                  className="t-line"
                  src="./assets/icons/t-line.png"
                  alt="line"
                />
              </div>
            </div>

            <div className="transactions-drawer-body">
              <div className="transation-main-wrap">
                <div className="transation-history-repeat">
                  <div className="transation-repeat-left">
                    <div className="transation-icon-box">
                      <img
                        className="t-bet-icon"
                        src="./assets/icons/bet.svg"
                        alt="img"
                      />
                    </div>

                    <div className="transation-history-info">
                      <h3> Bet </h3>
                      <p> Today, 11:00 am </p>
                    </div>
                  </div>
                  <div className="transation-repeat-right">
                    <h3> 0.001</h3>
                    <img src="./assets/icons/eth-icon.png" alt="img" />
                  </div>
                </div>
                <img
                  className="t-line"
                  src="./assets/icons/t-line.png"
                  alt="line"
                />
              </div>
            </div>

            <div className="transactions-drawer-body">
              <div className="transation-main-wrap">
                <div className="transation-history-repeat">
                  <div className="transation-repeat-left">
                    <div className="transation-icon-box">
                      <img
                        className="t-bet-icon"
                        src="./assets/icons/won.svg"
                        alt="img"
                      />
                    </div>

                    <div className="transation-history-info">
                      <h3> You Won </h3>
                      <p> Today, 12:00 am </p>
                    </div>
                  </div>
                  <div className="transation-repeat-right">
                    <h3> 0.002</h3>
                    <img src="./assets/icons/eth-icon.png" alt="img" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferHistory;
