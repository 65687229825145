import React, { useEffect, useState } from "react";
import TransferModal from "../Modals/TransferModal";
import { servicePost } from "../../helper/api";
import { SetTxDependenc } from "../../redux/Actions/soundActions";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import loader from "../lottie-files/loader.json";
import Web3 from "web3";
import { web3 } from "../../constants/constant";
const rsp = require("../../abis/rsp.json");
const AndroidProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isTransferClicked, setIsTransferCliecked] = useState(false);
  const [switchTransfer, setSwitchTransfer] = useState(false);
  const [amount, setAmount] = useState();
  const dispatch = useDispatch();
  const txDep = useSelector((state) => state.sound.txDep);
  const [balance, setBalance] = useState(0);
  const [account, setAccount] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const handleIconClick = () => {
    setSwitchTransfer(!switchTransfer);
  };

  // Restrict e from Amount input
  document
    .querySelector(".restrict-e")
    ?.addEventListener("keypress", function (evt) {
      if (
        evt.which != 8 &&
        evt.which != 0 &&
        (evt.which < 48 || evt.which > 57) &&
        evt.which != 46
      ) {
        evt.preventDefault();
      }
    });
  /////////////////////////////////////////////
  let User = "";
  var finalAccount = "";
  const provider = localStorage.getItem("wallet");
  const setData = async () => {
    // const loggedUser = await web3.eth.getAccounts();
    User = provider;
    //alert(provider);
    if (provider !== null) {
      //alert("provider");
      const firstdigt = User.slice(0, 4);
      const lastdigt = User.substr(0 - 4);
      // console.log(lastdigt);
      finalAccount = firstdigt + "....." + lastdigt;

      setAccount(finalAccount);
      const bal = await web3.eth.getBalance(User);
      const bal1 = web3.utils.fromWei(bal, "ether");
      const bal2 = parseFloat(bal1).toFixed(4);
      //  setAccount(userAccount);
      setBalance(bal2);
      // setBalance(bal2);
      const rspAbi = rsp.abi;
      const contract = new web3.eth.Contract(
        rspAbi,
        "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
      );
      // playerBalance
      let cont2 = await contract.methods.playerBalance(User).call();
      // console.log(cont2);
      let balances = web3.utils.fromWei(cont2, "ether");
      // console.log(balances);
      setTokenBalance(balances);
    }
    //}
    // if (userData) {
    //   serviceGet(
    //     `api/v1/upload/userprofile/${userData.id}/profileImageLink`,
    //     {}
    //   )
    //     .then((profile) => {
    //       setImage(profile.data.profileImageType);
    //     })
    //     .catch((err) => {
    //       // ////console.log("");
    //     });
    //   setUserName(userData.name);
    // }
  };

  //////////////////////////////////////////////////////////
  // Close Modal after 3 sec..
  useEffect(() => {
    setTimeout(() => {
      setModalShow(false);
    }, 2000);
  }, [modalShow]);

  const goToConfirmation = () => {
    if (amount > 0) {
      setIsTransferCliecked(true);
    } else if (amount == 0) {
      alert("Enter amount grater then 0");
    } else {
      alert("Please Enter Amount");
    }
  };

  const txType = switchTransfer ? "RPS Wallet to Meta" : "Meta to RPS Wallet";

  const createTransaction = async () => {
    if (!amount || amount == 0) {
      return;
    }
    try {
      const loggedUser = await web3.eth.getAccounts();
      User = loggedUser;
      const ether = web3.utils.toWei(amount, "ether");
      let txhash = "";
      // alert(User[0]);
      //  alert(User);

      setIsLoading(true);
      const rspAbi = rsp.abi;
      const contract = new web3.eth.Contract(
        rspAbi,
        "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
      );
      if (txType == "Meta to RPS Wallet") {
        // console.log(txType);
        let deposit = await contract.methods
          .deposit()
          .send({ from: User[0], value: ether })
          .then(async (val) => {
            // console.log("val=", val);
            txhash = val.transactionHash;
          });
      } else if (txType == "RPS Wallet to Meta") {
        // console.log(txType);
        let withdraw = await contract.methods
          .withdraw(ether)
          .send({ from: User[0] })
          .then(async (val) => {
            // console.log("val=", val);
            txhash = val.transactionHash;
          });
      }

      const transactionRes = await servicePost("transaction/depositWithdraw", {
        walletId: User[0],
        provider: "metamask",
        amount: amount,
        currency: "ETH",
        transactionHash: txhash,
        transactionType: txType,
      });

      if (transactionRes) {
        setIsLoading(false);
        setModalShow(true);
        setIsTransferCliecked(false);
        // <a
        //   href="intent://142.132.224.108:3002/#Intent;scheme=https;package=com.example.gyantek_rps;end"
        //   target="_new"
        // ></a>;
        dispatch(
          SetTxDependenc({
            Dep1: txDep + 1,
          })
        );
      }
    } catch (err) {
      console.log("error", err);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setData();
  });
  return (
    <>
      <TransferModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="connect-wallet-wrapper">
        <div className="connect-wallet-wrap">
          <div className="connect-wallet-header">
            <h2>Profile</h2>
            <a
              href="intent://nft.blockrhino.io/#Intent;scheme=https;package=com.shahjad.walletconnectlibadd;end"
              target="_new"
            >
              <img src="../assets/icons/back-arrow.png" alt="icon" />
            </a>
          </div>
          <div className="profile-drawer-body">
            <div className="profile-info-wrap">
              <div className="profile-left-info">
                <div className="profile-hold-box">
                  <img
                    className="metamask-icon"
                    src="../assets/icons/metamask.png"
                    alt="img"
                  />
                </div>
                <p>{account} </p>
                <div className="eth-icon-wrap metamask-balance">
                  <p>{balance} ETH</p>
                  <img
                    className="eth-icon"
                    src="../assets/icons/eth-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
              <div className="profile-right-info">
                <div className="profile-hold-box">
                  <img
                    className="wallet-icon"
                    src="../assets/icons/wallet-icon.png"
                    alt="img"
                  />
                </div>
                {/* <h3> Wallet </h3> */}
                <p> {account} </p>
                <div className="eth-icon-wrap metamask-balance">
                  <p>{tokenBalance} ETH</p>
                  <img
                    className="eth-icon"
                    src="../assets/icons/eth-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="transfer-main-wrapper">
          <div className="transfer-main-wrap">
            <div className="transfer-header">
              <h3> Transfer Amount </h3>
            </div>

            {!isTransferClicked ? (
              <>
                <div className="transfer-options-wrap">
                  {switchTransfer ? (
                    <>
                      <div className="rps-icon-wrap rps-transfer-wrap">
                        <img src="../assets/icons/rps-icon.png" alt="img" />
                        <h5> RPS bet</h5>
                      </div>

                      <div className="transfer-icon">
                        <img
                          onClick={handleIconClick}
                          src="../assets/icons/transfer.png"
                          alt="img"
                        />
                      </div>
                      <div className="rps-icon-wrap metamask-icon-warp">
                        <img src="../assets/icons/metamask.png" alt="img" />
                        <h5> Metamask</h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="metamask-transfer-wrap">
                        <img src="../assets/icons/metamask.png" alt="img" />
                        <h5> Metamask</h5>
                      </div>
                      <div className="transfer-icon">
                        <img
                          onClick={handleIconClick}
                          src="../assets/icons/transfer.png"
                          alt="img"
                        />
                      </div>
                      <div className="rps-icon-wrap">
                        <img src="../assets/icons/rps-icon.png" alt="img" />
                        <h5> RPS bet</h5>
                      </div>
                    </>
                  )}
                </div>
                <div className="transfer-price-wrap">
                  <div className="transfer-price-left">
                    <input
                      className="restrict-e"
                      type="number"
                      placeholder="Enter Amount"
                      min="0"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="devide-line">
                    <img src="../assets/icons/line.png" alt="img" />
                  </div>

                  <div className="transfer-price-right">
                    <img src="../assets/icons/eth-icon.png" alt="icon" />
                    <p> ETH </p>
                  </div>
                </div>
                <div className="transfer-amount-footer">
                  <div className="transfer-amount-text">
                    <img src="../assets/icons/shield.png" alt="img" />
                    <p> Enter the amount you want to transfer </p>
                  </div>
                  <div className="transfer-button">
                    <button onClick={goToConfirmation}>Transfer</button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {switchTransfer ? (
                  <div className="transfer-options-wrap">
                    <div className="rps-icon-wrap">
                      <img src="../assets/icons/rps-icon.png" alt="img" />
                      <h5> RPS bet</h5>
                    </div>
                    <div className="transfer-icon">
                      <h2> To </h2>
                      {/* <img src="../assets/icons/transfer.png" alt="img" /> */}
                    </div>
                    <div className="metamask-transfer-wrap metamask-transferClicked">
                      <img src="../assets/icons/metamask.png" alt="img" />
                      <h5> Metamask</h5>
                    </div>
                  </div>
                ) : (
                  <div className="transfer-options-wrap">
                    <div className="metamask-transfer-wrap metamask-transferClicked">
                      <img src="../assets/icons/metamask.png" alt="img" />
                      <h5> Metamask</h5>
                    </div>
                    <div className="transfer-icon">
                      <h2> To </h2>
                      {/* <img src="../assets/icons/transfer.png" alt="img" /> */}
                    </div>
                    <div className="rps-icon-wrap">
                      <img src="../assets/icons/rps-icon.png" alt="img" />
                      <h5> RPS bet</h5>
                    </div>
                  </div>
                )}

                <div className="transfer-amount-footer">
                  <div className="transfer-amount-confirm-text">
                    <img src="../assets/icons/shield.png" alt="img" />
                    <p>
                      Do you confirm that you wish to transfer the specified
                      amount to the RPS betting wallet?
                    </p>
                  </div>
                  <div className="transfer-confirm-button">
                    {isLoading ? (
                      <button disabled>
                        <div className="tranfer-button-loading">
                          <Lottie animationData={loader} loop={true}></Lottie>
                        </div>
                      </button>
                    ) : (
                      <button onClick={createTransaction}>Transfer</button>
                    )}

                    <button
                      className="transfer-cencel-btn"
                      onClick={() => setIsTransferCliecked(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AndroidProfile;
