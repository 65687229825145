import { ActionTypes } from "../Actions/action-type";

// src/Reducers/soundReducer.js
const initialState = {
  isSoundOn: true,
  audioElement: null,
  isPlaying: false,
  txDep: 0,
};

const soundReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SOUND":
      return {
        ...state,
        isSoundOn: !state.isSoundOn,
        isPlaying: state.isSoundOn, // Keep playing if sound was already on
      };
    case "TOGGLE_PLAYING":
      return {
        ...state,
        isPlaying: !state.isPlaying,
      };

    case ActionTypes.SET_TASK_DEP:
      return {
        ...state,
        txDep: action.payload,
      };

    default:
      return state;
  }
};

export default soundReducer;
