import React, { useState, useEffect, useRef } from "react";
import { Motion, spring } from "react-motion";
import "./Splash.css";
import Navbar from "./Navbar";
import clickSound from "../Sounds/sound.wav";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import noInternet from "./lottie-files/no-internet.json";

const Splash = () => {
  const [audio] = useState(new Audio(clickSound));
  const [centerIndex, setCenterIndex] = useState(0);
  const [showBounce, setShowBounce] = useState(true);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const navigate = useNavigate();

  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const images = [
    { src: "./assets/icons/rock.png", alt: "Rock" },
    { src: "./assets/icons/hand.png", alt: "Hand" },
    { src: "./assets/icons/scissor.png", alt: "Scissor" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCenterIndex((centerIndex) => (centerIndex + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  /******** Motion Bounce Code  */
  const [scale, setScale] = useState(2.5);

  const springConfig = { stiffness: 70, damping: 4 };
  const theStyle = {
    scale: spring(scale, springConfig),
  };
  const motionRef = useRef(null);
  useEffect(() => {
    setScale(1.1);
    setTimeout(() => {
      setShowBounce(false);
      setIsWalletConnected(true);
    }, 2000);
  }, []);

  // Play Slound When Click button
  const playSoundClick = () => {
    audio.play().catch((error) => {
      // console.log("Autoplay failed:", error);
    });
  };

  const goToselectMode = () => {
    navigate("/mode");
  };

  return (
    <>
      {/* <Navbar /> */}
      {isOnline ? (
        <div className="splash-main-wrapper">
          {/* {!showBounce ? <Navbar /> : <div className="navbar-main-wrap"></div>} */}
          <div className="navbar-space"> </div>
          <div className="splash-main-wrap">
            <Motion style={theStyle} key="theKey" ref={motionRef}>
              {({ scale }) => (
                <>
                  <div
                    className="splace-heading"
                    style={{
                      transform: `scale(${scale})`,
                    }}
                  >
                    <h2>
                      ROCK PAPER <br /> SCISSORS
                    </h2>
                  </div>
                  {showBounce && (
                    <div
                      className="splace-bounce-images"
                      style={{
                        transform: `scale(${scale})`,
                      }}
                    >
                      <img src="./assets/icons/scissor.png" alt="not found" />
                      <img
                        className="bounce-center-image"
                        src="./assets/icons/rock.png"
                        alt="not found"
                      />
                      <img src="./assets/icons/hand.png" alt="not found" />
                    </div>
                  )}
                </>
              )}
            </Motion>

            {!showBounce && (
              <div className="splash-carousel">
                <div className="splash-carousel-content">
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className={`splash-carousel-item ${
                        index === centerIndex ? "center-item" : ""
                      }`}
                      style={{
                        opacity: index === centerIndex ? 1 : 1,
                        transition:
                          index === centerIndex
                            ? "opacity 0.3s ease-in-out 0.2s"
                            : "opacity 0.3s ease-in-out",
                      }}
                    >
                      <img src={image.src} alt={image.alt} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Splash;
