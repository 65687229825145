import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "./StartGame.css";
import LoseModal from "../Modals/LoseModal";
import WonModal from "../Modals/WonModal";
import { useNavigate } from "react-router-dom";
import timerSound from "../../Sounds/timer_sound.wav";
import loader from "../lottie-files/loader.json";
import Lottie from "lottie-react";
import { playBtnClickSound } from "../../Sounds/SoundController";
import { useSelector } from "react-redux";
import noInternet from "../lottie-files/no-internet.json";
import { servicePost } from "../../helper/api";
import { web3 } from "../../constants/constant";

const rsp = require("../../abis/rsp.json");
const BOXES = [
  { id: 1, active: true, src: "./assets/icons/rock.png", move: "rock" },
  { id: 2, active: false, src: "./assets/icons/scissor.png", move: "scissor" },
  { id: 3, active: false, src: "./assets/icons/hand.png", move: "paper" },
];

const INTERVAL_DELAY = 1000;

const StartGame = () => {
  const [gameStarted, setGameStared] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [isLoseSound, setIsLoseSound] = useState(false);
  const [isWinSound, setIsWinSound] = useState(false);
  const [seconds, setSeconds] = useState(11);
  const [isTimerSound, setIsTimerSound] = useState(false);
  const [loseModalShow, setLoseModalShow] = useState(false);
  const [wonModalShow, setWonModalShow] = useState(false);
  const [boxes, setBoxes] = useState(BOXES);
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedGesture, setSelectedGesture] = useState("");
  const [opponentGesture, setOpponentGester] = useState();
  const [gameResult, setGameResult] = useState("");
  const [color, setColor] = useState("");
  const [opponentData, setOpponentData] = useState();
  const [player1Data, setplayer1Data] = useState();
  const [player2Data, setplayer2Data] = useState();
  const [player2Result, setPlayer2Result] = useState();
  const [userWalletId, setUserWalletId] = useState();
  console.log("userWalletId", userWalletId);

  const getUserWalletId = async () => {
    const accounts = await web3.eth.getAccounts();
    setUserWalletId(accounts[0]);
  };

  useEffect(() => {
    getUserWalletId();
  }, []);

  const navigate = useNavigate();
  const isSoundOn = useSelector((state) => state.sound.isPlaying);

  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Count down
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setIsTimerSound(true);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  useEffect(() => {
    if (seconds == 0) {
      timerAudio.pause();
    }
    if (seconds == 5) {
      setColor("yellow");
    }

    if (seconds == 0 && selectedGesture == "") {
      setLoseModalShow(true);
      setIsLoseSound(true);
    }
  }, [seconds]);

  // Auto add Active Class untill Select any gestured
  useEffect(() => {
    if (selectedBox === null) {
      const interval = setInterval(() => {
        setBoxes((prevBoxes) =>
          prevBoxes.map((box, index) => ({
            ...box,
            active:
              index ===
              (prevBoxes.findIndex((box) => box.active) + 1) % prevBoxes.length,
          }))
        );
      }, INTERVAL_DELAY);
      return () => clearInterval(interval);
    } else {
      setBoxes((prevBoxes) =>
        prevBoxes.map((box) => ({
          ...box,
          active: box.id === selectedBox,
        }))
      );
    }
  }, [selectedBox]);

  // Win/lose After 3 sec. Go to Bet page
  useEffect(() => {
    setTimeout(() => {
      setLoseModalShow(false);
      setWonModalShow(false);
      if (loseModalShow || wonModalShow) {
        localStorage.setItem("GameStarted", null);
        navigate("/bet");
      }
    }, 3000);
  }, [loseModalShow, wonModalShow]);

  // Wait Opponent Choosing Guester
  useEffect(() => {
    setTimeout(() => {
      // if (selectedGesture == "./assets/icons/rock.png") {
      //   setOpponentGester("./assets/icons/scissor.png");
      //   setGameResult("Rock crushes Scissors");
      // } else if (selectedGesture == "./assets/icons/scissor.png") {
      //   setOpponentGester("./assets/icons/scissor.png");
      //   setGameResult("TIE");
      // } else if (selectedGesture == "./assets/icons/hand.png") {
      //   setOpponentGester("./assets/icons/scissor.png");
      //   setGameResult("Scissors cut Paper");
      // }

      if (opponentData?.player2Move == "scissor") {
        setOpponentGester("./assets/icons/scissor.png");
        if (opponentData?.player1Move === "rock") {
          setGameResult("Rock crushes Scissors");
          setPlayer2Result("lose");
        } else if (opponentData?.player1Move === "paper") {
          setGameResult("Scissors cut Paper");
          setPlayer2Result("won");
        } else if (opponentData?.player1Move === "scissor") {
          setGameResult("TIE");
          setPlayer2Result("tie");
        } else if (opponentData?.player1Move === null) {
          setGameResult("Scissors cut Paper");
          setPlayer2Result("won");
        }
      } else if (opponentData?.player2Move == "rock") {
        setOpponentGester("./assets/icons/rock.png");
        if (opponentData?.player1Move === "paper") {
          setGameResult("Paper covers Rock");
          setPlayer2Result("lose");
        } else if (opponentData?.player1Move === "scissor") {
          setGameResult("Rock crushes Scissors");
          setPlayer2Result("won");
        } else if (opponentData?.player1Move === "rock") {
          setGameResult("TIE");
          setPlayer2Result("tie");
        } else if (opponentData?.player1Move === null) {
          setGameResult("Rock crushes Scissors");
          setPlayer2Result("won");
        }
      } else if (opponentData?.player2Move == "paper") {
        setOpponentGester("./assets/icons/hand.png");
        if (opponentData?.player1Move === "scissor") {
          setGameResult("Scissors cut Paper");
          setPlayer2Result("lose");
        } else if (opponentData?.player1Move === "rock") {
          setGameResult("Paper covers Rock");
          setPlayer2Result("won");
        } else if (opponentData?.player1Move === "paper") {
          setGameResult("TIE");
          setPlayer2Result("tie");
        } else if (opponentData?.player1Move === null) {
          setGameResult("Paper covers Rock");
          setPlayer2Result("won");
        }
      } else if (opponentData?.player2Move == null) {
        setOpponentGester("./assets/icons/hand.png");
        if (opponentData?.player1Move === "scissor") {
          setGameResult("Scissors cut Paper");
          setPlayer2Result("lose");
        } else if (opponentData?.player1Move === "rock") {
          setGameResult("Rock crushes Scissors");
          setPlayer2Result("lose");
        } else if (opponentData?.player1Move === "paper") {
          setGameResult("Paper covers Rock");
          setPlayer2Result("lose");
        } else if (opponentData?.player1Move === null) {
          setGameResult("TIE");
          setPlayer2Result("tie");
        }
      }
    }, 20000);
  }, [selectedGesture, opponentData?.player1Move, opponentData?.player2Move]);

  // Set Game Result and open modal accordingly

  useEffect(() => {
    setTimeout(() => {
      setGameResult("");
      setIsloading(true);
    }, 2000);
  }, [gameResult]);

  // Set Game Result and open modal accordingly
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (gameResult == "Paper covers Rock") {
  //       alert("1");
  //       setIsloading(true);
  //       setLoseModalShow(true);
  //       setIsLoseSound(true);
  //       setIsloading(false);
  //       // Transaction
  //       const transaction = async () => {
  //         try {
  //           //const player=player1Data;
  //           const accounts = await web3.eth.getAccounts();
  //           console.log("accounts", accounts);
  //           //alert(player1Data);
  //           if (accounts[0] === player1Data) {
  //             console.log("con call");
  //             const transactionRes = await servicePost(
  //               "transaction/createTransaction",
  //               {
  //                 gameId: opponentData.gameId,
  //                 walletId: accounts[0],
  //                 provider: "metamask",
  //                 amount: "0.001",
  //                 currency: "eth",
  //                 player1: opponentData?.player1,
  //                 player2: opponentData?.player2,
  //                 player1move: opponentData?.player1Move,
  //                 player2move: opponentData?.player2Move,
  //                 transactionType: "Bet",
  //               }
  //             );
  //             setIsloading(false);
  //             localStorage.setItem("GameStarted", null);
  //             navigate("/bet");
  //           } else if (accounts[0] === player2Data) {
  //             const transactionRes = await servicePost(
  //               "transaction/getTransactionStatus",
  //               {
  //                 gameId: opponentData.gameId,
  //               }
  //             );
  //             if (transactionRes.data == "SUCCESS") {
  //               setIsloading(false);
  //               localStorage.setItem("GameStarted", null);
  //               navigate("/bet");
  //             } else {
  //               const transactionRes = await servicePost(
  //                 "transaction/getTransactionStatus",
  //                 {
  //                   gameId: opponentData.gameId,
  //                 }
  //               );
  //               if (transactionRes.data == "SUCCESS") {
  //                 setIsloading(false);
  //                 localStorage.setItem("GameStarted", null);
  //                 navigate("/bet");
  //               }
  //             }
  //           }
  //           //  let txhash;
  //           //   const ether = web3.utils.toWei(0.001, "ether");
  //           //   const rspAbi=rsp.abi;
  //           //   const contract = new web3.eth.Contract(rspAbi, "0x1a3fbDd6aa84f20C13029F7A1feB71a5e92Aa2D5");
  //           //   let startnewgame = await contract.methods.newGame(opponentData.player2 , ether).send({ from: opponentData.player1 ,  })
  //           //   .then(async (val) => {
  //           //   //console.log("val=",val);
  //           //     txhash=val.transactionHash
  //           //     console.log("val.transactionHash=",val.transactionHash);
  //           //   });
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       };
  //       transaction();
  //     }
  //     if (gameResult == "Rock crushes Scissors") {
  //       alert("2");
  //       setWonModalShow(true);
  //       setIsloading(true);
  //       setIsWinSound(true);
  //       setIsloading(false);
  //       // Transaction
  //       const transaction = async () => {
  //         try {
  //           console.log("Rock crushes Scissors");
  //           const accounts = await web3.eth.getAccounts();
  //           console.log("accounts", accounts);
  //           alert(player1Data);
  //           if (accounts[0] === player1Data) {
  //             console.log("con call");

  //             const transactionRes = await servicePost(
  //               "transaction/createTransaction",
  //               {
  //                 gameId: opponentData.gameId,
  //                 walletId: accounts[0],
  //                 provider: "metamask",
  //                 amount: "0.001",
  //                 currency: "eth",
  //                 player1: opponentData?.player1,
  //                 player2: opponentData?.player2,
  //                 player1move: opponentData?.player1Move,
  //                 player2move: opponentData?.player2Move,
  //                 transactionType: "Bet",
  //               }
  //             );
  //             setIsloading(false);
  //             localStorage.setItem("GameStarted", null);
  //             navigate("/bet");
  //           } else if (accounts[0] === player2Data) {
  //             const transactionRes = await servicePost(
  //               "transaction/getTransactionStatus",
  //               {
  //                 gameId: opponentData.gameId,
  //               }
  //             );
  //             if (transactionRes.data == "SUCCESS") {
  //               setIsloading(false);
  //               localStorage.setItem("GameStarted", null);
  //               navigate("/bet");
  //             } else {
  //               const transactionRes = await servicePost(
  //                 "transaction/getTransactionStatus",
  //                 {
  //                   gameId: opponentData.gameId,
  //                 }
  //               );
  //               if (transactionRes.data == "SUCCESS") {
  //                 setIsloading(false);
  //                 localStorage.setItem("GameStarted", null);
  //                 navigate("/bet");
  //               }
  //             }
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       };
  //       transaction();
  //     } else if (gameResult == "Scissors cut Paper") {
  //       alert("3");
  //       setIsloading(true);
  //       setLoseModalShow(true);
  //       setIsLoseSound(true);
  //       setIsloading(false);

  //       // Transaction
  //       const transaction = async () => {
  //         try {
  //           console.log("Scissors cut Paper");
  //           const accounts = await web3.eth.getAccounts();
  //           console.log("accounts", accounts);
  //           alert(player1Data);
  //           if (accounts[0] === player1Data) {
  //             console.log("con call");

  //             const transactionRes = await servicePost(
  //               "transaction/createTransaction",
  //               {
  //                 gameId: opponentData.gameId,
  //                 walletId: accounts[0],
  //                 provider: "metamask",
  //                 amount: "0.001",
  //                 currency: "eth",
  //                 player1: opponentData?.player1,
  //                 player2: opponentData?.player2,
  //                 player1move: opponentData?.player1Move,
  //                 player2move: opponentData?.player2Move,
  //                 transactionType: "Bet",
  //               }
  //             );
  //             setIsloading(false);
  //             localStorage.setItem("GameStarted", null);
  //             navigate("/bet");
  //           } else if (accounts[0] === player2Data) {
  //             const transactionRes = await servicePost(
  //               "transaction/getTransactionStatus",
  //               {
  //                 gameId: opponentData.gameId,
  //               }
  //             );
  //             if (transactionRes.data == "SUCCESS") {
  //               setIsloading(false);
  //               localStorage.setItem("GameStarted", null);
  //               navigate("/bet");
  //             } else {
  //               const transactionRes = await servicePost(
  //                 "transaction/getTransactionStatus",
  //                 {
  //                   gameId: opponentData.gameId,
  //                 }
  //               );
  //               if (transactionRes.data == "SUCCESS") {
  //                 setIsloading(false);
  //                 localStorage.setItem("GameStarted", null);
  //                 navigate("/bet");
  //               }
  //             }
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       };
  //       transaction();
  //     } else if (gameResult == "TIE") {
  //       alert("4");
  //       console.log("TIE");
  //       setIsloading(true);
  //       setLoseModalShow(true);
  //       setIsLoseSound(true);
  //       setIsloading(false);

  //       // Transaction
  //       const transaction = async () => {
  //         try {
  //           console.log("Scissors cut Paper");
  //           const accounts = await web3.eth.getAccounts();
  //           console.log("accounts", accounts);
  //           alert(player1Data);
  //           if (accounts[0] === player1Data) {
  //             console.log("con call");

  //             const transactionRes = await servicePost(
  //               "transaction/createTransaction",
  //               {
  //                 gameId: opponentData.gameId,
  //                 walletId: accounts[0],
  //                 provider: "metamask",
  //                 amount: "0.001",
  //                 currency: "eth",
  //                 player1: opponentData?.player1,
  //                 player2: opponentData?.player2,
  //                 player1move: opponentData?.player1Move,
  //                 player2move: opponentData?.player2Move,
  //                 transactionType: "Bet",
  //               }
  //             );
  //             setIsloading(false);
  //             localStorage.setItem("GameStarted", null);
  //             navigate("/bet");
  //           } else if (accounts[0] === player2Data) {
  //             const transactionRes = await servicePost(
  //               "transaction/getTransactionStatus",
  //               {
  //                 gameId: opponentData.gameId,
  //               }
  //             );
  //             if (transactionRes.data == "SUCCESS") {
  //               setIsloading(false);
  //               localStorage.setItem("GameStarted", null);
  //               navigate("/bet");
  //             } else {
  //               const transactionRes = await servicePost(
  //                 "transaction/getTransactionStatus",
  //                 {
  //                   gameId: opponentData.gameId,
  //                 }
  //               );
  //               if (transactionRes.data == "SUCCESS") {
  //                 setIsloading(false);
  //                 localStorage.setItem("GameStarted", null);
  //                 navigate("/bet");
  //               }
  //             }
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       };
  //       transaction();
  //       //setIsloading(true);
  //       //setIsloading(false);
  //       // localStorage.setItem("GameStarted", null);
  //       //navigate("/bet");
  //     }
  //   }, 10000);
  // }, [selectedGesture, gameResult]);

  // Check Game Result

  useEffect(() => {
    setTimeout(() => {
      if (player2Result == "won") {
        alert("1");
        // Transaction
        const transaction = async () => {
          try {
            //const player=player1Data;
            const accounts = await web3.eth.getAccounts();
            console.log("accounts", accounts);
            //alert(player1Data);
            if (accounts[0] === player1Data) {
              console.log("con call");

              setIsloading(true);
              setLoseModalShow(true);
              setIsLoseSound(true);
              setIsloading(false);
              const transactionRes = await servicePost(
                "transaction/createTransaction",
                {
                  gameId: opponentData.gameId,
                  walletId: accounts[0],
                  provider: "metamask",
                  amount: "0.001",
                  currency: "eth",
                  player1: opponentData?.player1,
                  player2: opponentData?.player2,
                  player1move: opponentData?.player1Move,
                  player2move: opponentData?.player2Move,
                  transactionType: "Bet",
                }
              );
              setIsloading(false);
              localStorage.setItem("GameStarted", null);
              console.log("transactionRes", transactionRes);
              navigate("/bet");
            } else if (accounts[0] === player2Data) {
              setWonModalShow(true);
              setIsloading(true);
              setIsWinSound(true);
              setIsloading(false);
              const transactionRes = await servicePost(
                "transaction/getTransactionStatus",
                {
                  gameId: opponentData.gameId,
                }
              );
              console.log("transactionRes2", transactionRes);
              if (transactionRes.data == "SUCCESS") {
                setIsloading(false);
                localStorage.setItem("GameStarted", null);
                navigate("/bet");
              } else {
                const transactionRes = await servicePost(
                  "transaction/getTransactionStatus",
                  {
                    gameId: opponentData.gameId,
                  }
                );
                console.log("transactionRes3", transactionRes);
                if (transactionRes.data == "SUCCESS") {
                  setIsloading(false);
                  localStorage.setItem("GameStarted", null);
                  navigate("/bet");
                }
              }
            }
            //  let txhash;
            //   const ether = web3.utils.toWei(0.001, "ether");
            //   const rspAbi=rsp.abi;
            //   const contract = new web3.eth.Contract(rspAbi, "0x1a3fbDd6aa84f20C13029F7A1feB71a5e92Aa2D5");
            //   let startnewgame = await contract.methods.newGame(opponentData.player2 , ether).send({ from: opponentData.player1 ,  })
            //   .then(async (val) => {
            //   //console.log("val=",val);
            //     txhash=val.transactionHash
            //     console.log("val.transactionHash=",val.transactionHash);
            //   });
          } catch (error) {
            console.log(error);
          }
        };
        transaction();
      }
      if (player2Result == "lose") {
        alert("2");

        // Transaction
        const transaction = async () => {
          try {
            console.log("Rock crushes Scissors");
            const accounts = await web3.eth.getAccounts();
            console.log("accounts", accounts);
            alert(player1Data);
            if (accounts[0] === player1Data) {
              console.log("con call");

              setWonModalShow(true);
              setIsloading(true);
              setIsWinSound(true);
              setIsloading(false);

              const transactionRes = await servicePost(
                "transaction/createTransaction",
                {
                  gameId: opponentData.gameId,
                  walletId: accounts[0],
                  provider: "metamask",
                  amount: "0.001",
                  currency: "eth",
                  player1: opponentData?.player1,
                  player2: opponentData?.player2,
                  player1move: opponentData?.player1Move,
                  player2move: opponentData?.player2Move,
                  transactionType: "Bet",
                }
              );
              setIsloading(false);
              localStorage.setItem("GameStarted", null);
              navigate("/bet");
            } else if (accounts[0] === player2Data) {
              setIsloading(true);
              setLoseModalShow(true);
              setIsLoseSound(true);
              setIsloading(false);
              const transactionRes = await servicePost(
                "transaction/getTransactionStatus",
                {
                  gameId: opponentData.gameId,
                }
              );
              if (transactionRes.data == "SUCCESS") {
                setIsloading(false);
                localStorage.setItem("GameStarted", null);
                navigate("/bet");
              } else {
                const transactionRes = await servicePost(
                  "transaction/getTransactionStatus",
                  {
                    gameId: opponentData.gameId,
                  }
                );
                if (transactionRes.data == "SUCCESS") {
                  setIsloading(false);
                  localStorage.setItem("GameStarted", null);
                  navigate("/bet");
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
        };
        transaction();
      } else if (player2Result == "tie") {
        alert("Tie");
        console.log("TIE");
        setIsloading(true);
        setLoseModalShow(true);
        setIsLoseSound(true);
        setIsloading(false);

        // Transaction
        const transaction = async () => {
          try {
            console.log("Scissors cut Paper");
            const accounts = await web3.eth.getAccounts();
            console.log("accounts", accounts);
            alert(player1Data);
            if (accounts[0] === player1Data) {
              console.log("con call");

              const transactionRes = await servicePost(
                "transaction/createTransaction",
                {
                  gameId: opponentData.gameId,
                  walletId: accounts[0],
                  provider: "metamask",
                  amount: "0.001",
                  currency: "eth",
                  player1: opponentData?.player1,
                  player2: opponentData?.player2,
                  player1move: opponentData?.player1Move,
                  player2move: opponentData?.player2Move,
                  transactionType: "Bet",
                }
              );
              setIsloading(false);
              localStorage.setItem("GameStarted", null);
              navigate("/bet");
            } else if (accounts[0] === player2Data) {
              const transactionRes = await servicePost(
                "transaction/getTransactionStatus",
                {
                  gameId: opponentData.gameId,
                }
              );
              if (transactionRes.data == "SUCCESS") {
                setIsloading(false);
                localStorage.setItem("GameStarted", null);
                navigate("/bet");
              } else {
                const transactionRes = await servicePost(
                  "transaction/getTransactionStatus",
                  {
                    gameId: opponentData.gameId,
                  }
                );
                if (transactionRes.data == "SUCCESS") {
                  setIsloading(false);
                  localStorage.setItem("GameStarted", null);
                  navigate("/bet");
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
        };
        transaction();
        //setIsloading(true);
        //setIsloading(false);
        // localStorage.setItem("GameStarted", null);
        //navigate("/bet");
      }
    }, 10000);
  }, [selectedGesture, gameResult]);

  // Play Slound When Click button
  const [timerAudio] = useState(new Audio(timerSound));

  // Play Timer Sound...
  useEffect(() => {
    timerAudio.loop = true; // Enable looping
    if (isTimerSound) {
      timerAudio.currentTime = 0; // Reset the audio to the beginning
      if (isSoundOn) {
        timerAudio.play().catch((error) => {
          console.log("Autoplay failed:", error);
        });
      }
    } else {
      timerAudio.pause();
      timerAudio.currentTime = 0; // Reset the audio to the beginning
    }

    return () => {
      timerAudio.pause();
      timerAudio.currentTime = 0; // Reset the audio to the beginning
    };
  }, [isTimerSound, isSoundOn]);

  const addMove = async (move) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const moveData = await servicePost("oppenent/addMove", {
        walletId: accounts[0],
        move: move,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getOpponent = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const response = await servicePost("oppenent/getoppenent", {
        walletId: accounts[0],
      });
      setOpponentData(response.data);
      setplayer1Data(response.data.player1);
      console.log("setplayer1Data", player1Data);
      setplayer2Data(response.data.player2);
      console.log("setplayer2Data", player2Data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOpponent();
  }, [seconds]);

  // useEffect(() => {
  //   window.history.pushState(null, "", window.location.href);

  //   window.onpopstate = function () {
  //     window.history.pushState(null, "", window.location.href);
  //   };
  // }, []);

  return (
    <>
      {/* <Navbar /> */}
      <WonModal
        show={wonModalShow}
        onHide={() => setWonModalShow(false)}
        isWinSound={isWinSound}
      />
      <LoseModal
        show={loseModalShow}
        onHide={() => setLoseModalShow(false)}
        isLoseSound={isLoseSound}
      />

      {/****************** Desktop View  *****/}
      {isOnline ? (
        <div className="start-game-main-wrapper desktop-view">
          <div className="navbar-space"> </div>
          <div className="bet-price-wrapper">
            <img src="./assets/icons/gold-medal.png" alt="icon" />
            <p> 0.002 </p>
          </div>
          <div className="start-game-main-wrap">
            <div className="players-name-wrap">
              <h3>Opponent </h3>
              <h3> You </h3>
            </div>
            <div className="game-choose-gesture-wrap">
              <div className="opponent-gesture-wrap">
                {opponentGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img
                      className="opponent-choose-gesture"
                      src={opponentGesture}
                      alt="img"
                    />
                  </div>
                ) : selectedGesture ? (
                  <div className="player-wave-wrap">
                    <img src="./assets/icons/hand.png" alt="img" />
                    <p>
                      Opponent is choosing a <br /> Gesture...
                    </p>
                  </div>
                ) : (
                  <p>
                    Opponent choosing a <br /> Gesture after you select
                  </p>
                )}
              </div>

              <div className="choose-guester-center">
                {opponentGesture ? (
                  <div className="result-text">
                    {gameResult == "" && isLoading ? (
                      <div className="loading-animation">
                        <Lottie animationData={loader} loop={true}></Lottie>
                      </div>
                    ) : (
                      <h4> {gameResult} </h4>
                    )}
                  </div>
                ) : seconds == 51 ? (
                  <div className="start-game-timmer-wrap">
                    <h5> START </h5>
                  </div>
                ) : (
                  <div className="countdown-container">
                    <h2
                      className={
                        seconds === 0
                          ? `countdown-text ${color} finished`
                          : `countdown-text ${color}`
                      }
                    >
                      {seconds}
                    </h2>
                  </div>
                )}
              </div>

              <div className="player-gesture-wrap">
                {selectedGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img src={selectedGesture} alt="img" />
                  </div>
                ) : (
                  <div className="player-wave-wrap">
                    <img src="./assets/icons/hand.png" alt="img" />
                    <p>
                      To begin the game, <br /> please choose a gesture
                    </p>
                  </div>
                )}

                <div className="choose-gesture-wrapper">
                  {boxes.map((box) => (
                    <div
                      key={box.id}
                      className={`choose-gesture-option ${
                        box.active ? "active" : ""
                      }`}
                      onClick={() => {
                        if (!selectedGesture) {
                          setSelectedBox(box.id);
                          addMove(box.move);
                          // transaction();
                          setSelectedGesture(box.src);
                          setSeconds(seconds);
                          playBtnClickSound(isSoundOn);
                          setColor("");
                          timerAudio.play();
                        }
                      }}
                    >
                      <img src={box.src} alt="img" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}

      {/****************** Mobile View  *****/}

      {isOnline ? (
        <div className="start-game-main-wrapper mobile-view">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space-start"> </div>
          <div className="start-game-main-wrap">
            <div className="players-name-wrap">
              <h3>Opponent </h3>
              <div className="bet-price-wrapper">
                <img src="./assets/icons/gold-medal.png" alt="icon" />
                <p> 0.002 </p>
              </div>
            </div>
            <div className="mobile-game-choose-gesture-wrap">
              <div className="opponent-gesture-wrap mobile-opponent-gester-wrap">
                {opponentGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img
                      className="opponent-choose-gesture"
                      src={opponentGesture}
                      alt="img"
                    />
                  </div>
                ) : selectedGesture ? (
                  <div className="player-wave-wrap">
                    <img src="./assets/icons/hand.png" alt="img" />
                    <p>
                      Opponent is choosing a <br /> Gesture...
                    </p>
                  </div>
                ) : (
                  <p>
                    Opponent choosing a <br /> Gesture after you select
                  </p>
                )}
              </div>

              <div className="mobile-choose-guester-center">
                {opponentGesture ? (
                  <div className="result-text">
                    {gameResult == "" && isLoading ? (
                      <div className="loading-animation">
                        <Lottie animationData={loader} loop={true}></Lottie>
                      </div>
                    ) : (
                      <h4> {gameResult} </h4>
                    )}
                  </div>
                ) : seconds == 11 ? (
                  <div className="start-game-timmer-wrap">
                    <h5> START </h5>
                  </div>
                ) : (
                  <div className="countdown-container">
                    <h2
                      className={
                        seconds === 0
                          ? `countdown-text ${color} finished`
                          : `countdown-text ${color}`
                      }
                    >
                      {seconds}
                    </h2>
                  </div>
                )}
              </div>

              <div className="mobile-player-gesture-wrap">
                {selectedGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img src={selectedGesture} alt="img" />
                  </div>
                ) : (
                  <div className="player-wave-wrap">
                    <img src="./assets/icons/hand.png" alt="img" />
                    <p>
                      To begin the game, <br /> please choose a gesture
                    </p>
                  </div>
                )}

                <div className="game-players-name-wrap">
                  <h3>you </h3>
                </div>

                <div className="choose-gesture-wrapper">
                  {boxes.map((box) => (
                    <div
                      key={box.id}
                      className={`choose-gesture-option ${
                        box.active ? "active" : ""
                      }`}
                      onClick={() => {
                        if (!selectedGesture) {
                          setSelectedBox(box.id);
                          setSelectedGesture(box.src);
                          setSeconds(11);
                          playBtnClickSound(isSoundOn);
                          addMove(box.move);
                          setColor("");
                          timerAudio.play();
                        }
                      }}
                    >
                      <img src={box.src} alt="img" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StartGame;
