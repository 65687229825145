import axios from "axios";
import { SERVICE_URL } from "./Utility";

const token = localStorage.getItem("token" && "token");
const csrfToken = localStorage.getItem("access");

const headers = {
  "auth-token": token,
};

// handle Post requests
const servicePost = async (path, payload) => {
  console.log("SERVICE_URL", SERVICE_URL);
  try {
    const response = await axios.post(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// handle Patch requests
const serviceUpdate = async (path, payload, headers) => {
  try {
    const response = await axios.patch(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        "auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// handle Get requests
const serviceGet = async (path) => {
  try {
    const response = await axios.get(`${SERVICE_URL}/${path}`, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": token,
      },
      credentials: "include",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// handle Delete requests
const serviceDelete = async (path, payload) => {
  try {
    const response = await axios.delete(`${SERVICE_URL}/${path}`, {
      headers: {
        "auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// handle Put requests
const servicePut = async (path, payload) => {
  try {
    const response = await axios.put(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        "auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { servicePost, serviceGet, serviceUpdate, serviceDelete, servicePut };
