import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Splash from "./Components/Splash";
import SelectMode from "./Components/mode/SelectMode";
import Bet from "./Components/mode/Bet";
import WaitingOpponent from "./Components/mode/WaitingOpponent";
import StartGame from "./Components/Game/StartGame";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import { useEffect } from "react";
import AndroidProfile from "./Components/AndroidApp/AndroidProfile";
import AndroidBet from "./Components/AndroidApp/AndroidBet";
import OpponentWait from "./Components/AndroidApp/OpponentWait";
import AndroidGame from "./Components/AndroidApp/AndroidGame";
import {
  ProtectedAuth,
  ProtectedGame,
  ProtectedRestrict,
  ProtectedRoute,
} from "./routing/PrivateRoute";
import ComputerGame from "./Components/Game/ComputerGame";

function App() {
  return (
    <>
      <Navbar />

      <Routes>
        <Route element={<ProtectedAuth />}>
          <Route path="/" element={<Splash />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route element={<ProtectedRestrict />}>
            <Route path="/home" element={<Home />} />
            <Route path="/mode" element={<SelectMode />} />
            <Route path="/bet" element={<Bet />} />
            <Route path="/opponent-waiting" element={<WaitingOpponent />} />

            {/************* Android app intigration url  */}
            <Route path="/android/profile" element={<AndroidProfile />} />
            <Route path="/android-bet" element={<AndroidBet />} />
            <Route
              path="/android/opponent-waiting"
              element={<OpponentWait />}
            />
          </Route>

          <Route element={<ProtectedGame />}>
            {/* <Route path="/start-game" element={<StartGame />} /> */}
            <Route path="/start-game" element={<ComputerGame />} />
            <Route path="/android/start-game" element={<AndroidGame />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
