import { ActionTypes } from "./action-type";
export const toggleSound = () => {
  return {
    type: "TOGGLE_SOUND",
  };
};

export const SetTxDependenc = (data) => {
  return {
    type: ActionTypes.SET_TASK_DEP,
    payload: data,
  };
};
