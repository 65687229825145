import Web3 from "web3";
const ethereum = typeof window != undefined ? window.ethereum : {};
export var web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
export const METAMASK_RECEIVER_ACCOUNT = "";

export const initializeWeb3 = async () => {
  const provider = localStorage.getItem("provider");
    if (provider == '"metamask"') {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      
    }  
};