import React, { useEffect, useState } from "react";
import "./Navbar.css";
import ConnectWallet from "./Drawers/ConnectWallet";
import clickSound from "../Sounds/sound.wav";
import { toggleSound } from "../redux/Actions/soundActions";
import ProfileDrawer from "./Drawers/ProfileDrawer";
import TransferHistory from "./Drawers/TransferHistory";
import { useDispatch, useSelector } from "react-redux";
import backgroundMusic from "../Sounds/backround-music.wav";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [audio] = useState(new Audio(clickSound));
  const [bgAudio] = useState(new Audio(backgroundMusic));
  const [showLogout, setShowLogout] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const isSoundOn = useSelector((state) => state.sound.isPlaying);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Play Slound When Click button
  const playSoundClick = () => {
    audio.play().catch((error) => {
      // console.log("Autoplay failed:", error);
    });
  };

  const playBgMusic = () => {
    bgAudio.loop = true; // Enable looping
    dispatch(toggleSound());
    if (bgAudio && !bgAudio.paused) {
      // Music is already playing, no need to play again
      return;
    }
    bgAudio.play().catch((error) => {
      // console.log("Autoplay failed:", error);
    });
  };

  const stopBgMusic = () => {
    dispatch(toggleSound());
    bgAudio.pause();
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasConnectWallet"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <ConnectWallet />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasProfile"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <ProfileDrawer />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasTransfer"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <TransferHistory />
        </div>
      </div>

      <div className="navbar-main-wrapper">
        <div className="navbar-main-wrap">
          <div className="nav-left">
            <img src="./assets/icons/logo.png" />
          </div>
          <div className="nav-right">
            {pathname !== "/" ? (
              <div className="connected-icons">
                {pathname == "/opponent-waiting" ||
                pathname == "/start-game" ? (
                  ""
                ) : (
                  <span tooltip="Logout" flow="down">
                    <img
                      onClick={logout}
                      src="./assets/icons/logout.png"
                      alt="icon"
                    />
                  </span>
                )}

                {isSoundOn ? (
                  <>
                    <span tooltip="Disable" flow="down">
                      <img
                        src="./assets/icons/sound-on.png"
                        alt="icon"
                        onClick={stopBgMusic}
                      />
                    </span>
                  </>
                ) : (
                  <span tooltip="Enable" flow="down">
                    <img
                      src="./assets/icons/sound-off.png"
                      alt="icon"
                      onClick={playBgMusic}
                    />
                  </span>
                )}
                <span tooltip="Transfer" flow="down">
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTransfer"
                    aria-controls="offcanvasRight"
                    src="./assets/icons/transfer-icon.png"
                    alt="icon"
                  />
                </span>
                <span tooltip="Profile" flow="down">
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasProfile"
                    aria-controls="offcanvasRight"
                    className="user-avtar"
                    src="./assets/icons/user-avtar.png"
                    alt="icon"
                  />
                </span>
              </div>
            ) : (
              <button
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasConnectWallet"
                aria-controls="offcanvasRight"
                onClick={playSoundClick}
              >
                Connect wallet
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
