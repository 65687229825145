import React, { useEffect, useState } from "react";
import "./Drawer.css";
import ConnectModal from "../Modals/ConnectModal";
import clickSound from "../../Sounds/sound.wav";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import Lottie from "lottie-react";
import loader from "../lottie-files/loader-blue.json";
import Web3 from "web3";
import { isMobile } from "react-device-detect";

const ConnectWallet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [audio] = useState(new Audio(clickSound));

  const navigate = useNavigate();
  // Play Slound When Click button
  const playSoundClick = () => {
    audio.play().catch((error) => {
      // console.log("Autoplay failed:", error);
    });
  };
  /////////////////////////////////////////////////////////
  // const connectToWallet = (param) => {
  //   switch (param) {
  //     case "metaMask":
  //       connectMetaMask();
  //       break;
  //     // case "MobilemetaMask":
  //     //   connectMobileMetaMask();
  //     //   break;
  //     // code block
  //   }
  // };

  ////////////////////////////////////////////////////

  const connectMetaMask = async () => {
    const ethereum = typeof window != undefined ? window.ethereum : {};
    const web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
    if (ethereum != undefined) {
      const networkId = await web3.eth.net.getId();
      // alert("networkId",networkId);
      if (networkId != 1) {
        setIsLoading(false);
        alert("Please Connect Ethereum Mainnet");
        // setModalVisible(true);
      } else {
        requestPermissions();
      }
    } else {
      setIsLoading(false);
      alert("Please Install Metamask");
      //setErrorContent("Please Install Metamask");
      //setModalVisible1(true);
    }
  };

  ////////////////////////////////////////////////////////////////
  const requestPermissions = async () => {
    const ethereum = typeof window != undefined ? window.ethereum : {};
    const web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
    ethereum
      .request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      })
      .then(async (permissions) => {
        const accountsPermission = permissions.find(
          (permission) => permission.parentCapability === "eth_accounts"
        );
        if (accountsPermission) {
          // ////console.log("eth_accounts permission successfully requested!");
        }
        await ethereum.request({ method: "eth_requestAccounts" });
        localStorage.setItem("provider", JSON.stringify("metamask"));

        submitUser(web3);

        // setLoggedData(true);
        // showConnectDialog();
      })
      .catch((error) => {
        if (error.code === 4001) {
          setIsLoading(false);
          // EIP-1193 userRejectedRequest error
          // ////console.log("Permissions needed to continue.");
        } else {
          setIsLoading(false);
          console.error(error);
        }
      });
  };
  //////////////////////////////////////////////////

  const submitUser = async (web3) => {
    //alert("S");
    const accounts = await web3.eth.getAccounts();
    setModalShow(true);
    let balance = await web3.eth.getBalance(accounts[0]);
    const data = await servicePost("authorize", {
      provider: "metamask",
      walletId: accounts[0],
    });
    //alert("data", data.token);
    if (data) {
      setIsLoading(false);
      //console.log(data);
      document.getElementById("closeConnectDrawer").click();
      localStorage.setItem("token", data.token);
      localStorage.setItem("wallet", data.data.walletId);
      setModalShow(true);
      navigate("/home");

      // if (isMobile) {
      //   navigate("/android-bet");
      // } else {
      //   navigate("/home");
      // }
    }
    // const userData = await authorizeWallet(accounts[0], "");
    // if (accounts.length > 0) {
    //   balance = web3.utils.fromWei(balance, "ether");
    //   balance = parseFloat(balance).toFixed(4);
    //  // alert("balance=",balance);
    //  // console.log("balance=",balance);

    // }

    // navigate("/home");
    // setUserData({ account: accounts[0], balance });
    //window.open("/", "_parent");
  };
  ///////////////////////////////////////////////////////////////
  const goToHome = () => {
    setTimeout(() => {
      setModalShow(false);
    }, 5000);
  };

  useEffect(() => {
    goToHome();
  }, [modalShow]);

  const connectWallet = async (param) => {
    try {
      setIsLoading(true);
      //alert("1");
      switch (param) {
        case "metaMask":
          connectMetaMask();
          break;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <span
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="closeConnectDrawer"
      ></span>
      <ConnectModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="connect-wallet-wrapper">
        <div className="connect-wallet-wrap">
          <div className="connect-wallet-header">
            <h2>
              Connect <span> Wallet </span>
            </h2>
            <img
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              src="./assets/icons/back-arrow.png"
              alt="icon"
            />
          </div>
          <div className="connect-wallet-body">
            <p>
              Choose how you want to connect. There are several wallet
              providers.
            </p>

            {isLoading ? (
              <div className="connect-wallet-options-wrap">
                <div className="connect-wallet-loader">
                  <Lottie animationData={loader} loop={true}></Lottie>
                </div>
              </div>
            ) : (
              <div
                className="connect-wallet-options-wrap"
                onClick={() => {
                  playSoundClick();
                  connectWallet("metaMask");
                }}
              >
                <img src="./assets/icons/metamask.png" alt="icon" />
                <h3> Meta Mask</h3>
              </div>
            )}

            <p>
              We do not own your private keys and cannot access your funds
              without your confirmation.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectWallet;
