import React, { useState, useEffect } from "react";
import "./SelectMode.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { playBtnClickSound } from "../../Sounds/SoundController";
import Lottie from "lottie-react";
import noInternet from "../lottie-files/no-internet.json";
import { servicePost } from "../../helper/api";
import loader from "../lottie-files/loader.json";
import { web3 } from "../../constants/constant";
import Navbar from "../Navbar";
const rsp = require("../../abis/rsp.json");
const Bet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isSoundOn = useSelector((state) => state.sound.isPlaying);
  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [parameterValue, setParameterValue] = useState(null);
  const [paramValue, setParamValue] = useState("");
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/mode");
  };

  const goToWaitingPage = () => {
    navigate("/opponent-waiting");
  };
  ///////////////////
  // Function to fetch data from the API
  const fetchDataFromApi = async (gameId) => {
    try {
      //const provider = localStorage.getItem("wallet");
      const response = await servicePost("oppenent/gamesId", {
        gameId: gameId,
      });
      console.log("response", response);
      //const data = await response.json();
      // console.log("data.data.gamesId", response.data.gamesId);
      var gamessId = response.data.gamesId;
      // console.log("gamessId", gamessId);
      return gamessId;
      // Assuming the API response contains a parameter named 'parameterValue'
      // setParameterValue(prevValue =>gamessId);
      // setParamValue(prevValue =>gamessId);
      // console.log("setParameterValue=",parameterValue);
      // console.log("setParamValue=",paramValue);
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };
  ////////////

  // Recursive function to check the parameter value and call the API again if necessary
  const checkParameterValue = async (currentValue, gameId) => {
    if (currentValue == null) {
      // Wait for 1 second before making the next API call
      console.log("currentValue", currentValue);
      console.log("gameId", gameId);
      const value = await fetchDataFromApi(gameId);
      if (value != null) {
        setParameterValue(value);
        setParamValue(value);
        console.log("setParameterValue=", parameterValue);
        console.log("setParamValue=", paramValue);
      }
      checkParameterValue(parameterValue, gameId); // Call the function again with the latest parameterValue
    } else {
      setTimeout(checkParameterValue(currentValue, gameId), 6000);
      //alert("done");
    }
  };

  // Create Game
  const createGame = async () => {
    // call contract
    const provider = localStorage.getItem("wallet");
    // console.log("provider", provider);
    // const rspAbi = rsp.abi;
    // const contract = new web3.eth.Contract(
    //   rspAbi,
    //   "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
    // );

    // // check Player balance > 0.001
    // let cont2 = await contract.methods.playerBalance(provider).call();
    // console.log("cont2", cont2);
    // let balances = web3.utils.fromWei(cont2, "ether");

    // if (balances < 0.001) {
    //   alert("Balance is low,please deposit balance to RSP");
    //   return;
    // }

    try {
      setIsLoading(true);
      let txhash;
      // const ether = web3.utils.toWei("0.001", "ether");
      // console.log("ether", ether);
      // const rspAbi = rsp.abi; //0x1a3fbDd6aa84f20C13029F7A1feB71a5e92Aa2D5
      // const contract = new web3.eth.Contract(
      //   rspAbi,
      //   "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
      // );
      // let startnewgame = await contract.methods
      //   .newGame(ether)
      //   .send({ from: provider, gas: 200000 })
      //   .then(async (val) => {
      //     txhash = val.transactionHash;
      //     console.log("val.transactionHash=", val);
      //     console.log("gameId=", val.events.gameInfo.returnValues.gameId);
      //     const gamesId = val.events.gameInfo.returnValues.gameId;

      //     // Call Create Game Api
      //     const createdGameRes = await servicePost("oppenent/startgame", {
      //       walletId: provider,
      //       provider: "metamask",
      //       amount: "0.001",
      //       player2: "0x415a6B59746EbdA819b3ce74d5715b25E633Ed89",
      //       gamesId: gamesId,
      //       transactionHash: txhash,
      //     });

      //     console.log("createdGameRes", createdGameRes);
      //   });

      const randomGameId = Date.now();
      console.log("randomGameId", randomGameId);

      // Call Create Game Api
      const createdGameRes = await servicePost("oppenent/startgame", {
        walletId: provider,
        provider: "metamask",
        amount: "0.001",
        player2: "0x415a6B59746EbdA819b3ce74d5715b25E633Ed89",
        gamesId: randomGameId,
        // transactionHash: txhash,
        transactionHash:
          "0x063834efe214f4199b1ad7181ce8c5ced3e15d271c8e866da7c89e86ee629cfb",
      });

      setIsLoading(false);
      goToWaitingPage();
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  useEffect(() => {
    //checkParameterValue(null,null);
    // Runs ONCE after initial rendering
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      {isOnline ? (
        <div className="splash-main-wrapper">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space"> </div>
          <div className="mode-select-wrapper">
            <img
              onClick={goBack}
              className="goback-mode-btn"
              src="./assets/icons/back-white.png"
              alt="icon"
            />
            <div className="mode-select-wrap">
              <h2>BET</h2>

              <div className="bet-price-box">
                <img src="./assets/icons/bet-box.png" alt="img" />
              </div>

              <div className="place-bet-wrapper">
                <div className="place-bet-text-wrap">
                  <img src="./assets/icons/white-shield.png" alt="icon" />
                  <h4> This Bet Amount is Fix for Every player </h4>
                </div>

                {isLoading ? (
                  <button disabled>
                    <div className="tranfer-button-loading">
                      <Lottie animationData={loader} loop={true}></Lottie>
                    </div>
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={() => {
                      playBtnClickSound(isSoundOn);
                      createGame();
                    }}
                  >
                    Place a Bet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Bet;
