import React from "react";

const InstructionsDrawer = () => {
  return (
    <div className="connect-wallet-wrapper">
      <div className="connect-wallet-wrap">
        <div className="connect-wallet-header">
          <h2>
            Game
            <span> Instructions </span>
          </h2>
          <img
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            src="./assets/icons/back-arrow.png"
            alt="icon"
          />
        </div>
        <div className="instruction-drawer-body">
          <div className="gestures-main-wrap">
            <h3> Gestures Sign Meaning </h3>
            <div className="sign-icon-wrapper">
              <div className="sign-icon-wrap">
                <img src="./assets/icons/sign1.png" alt="img" />
                <h4> Rock </h4>
              </div>

              <div className="sign-icon-wrap">
                <img src="./assets/icons/sign2.png" alt="img" />
                <h4> Paper </h4>
              </div>

              <div className="sign-icon-wrap">
                <img src="./assets/icons/sign3.png" alt="img" />
                <h4> Scissor </h4>
              </div>
            </div>
            {/* <img src="./assets/icons/game-sign.png" alt="img" /> */}
          </div>
          <div className="instructions-main-wrap">
            <div className="instruction-wrap">
              <div className="instruction-left">
                <h3> Instructions: </h3>

                <div className="instruction-repeat">
                  <img src="./assets/icons/dot-icon.png" alt="dot-icon" />
                  <p> Rock crushes Scissors </p>
                </div>
                <div className="instruction-repeat">
                  <img src="./assets/icons/dot-icon.png" alt="dot-icon" />
                  <p>Scissors cut Paper </p>
                </div>
                <div className="instruction-repeat">
                  <img src="./assets/icons/dot-icon.png" alt="dot-icon" />
                  <p> Paper covers Rock </p>
                </div>
                <div className="instruction-repeat">
                  <img src="./assets/icons/dot-icon.png" alt="dot-icon" />
                  <p> Facing each other with same thing = Tie </p>
                </div>
              </div>
              <div className="instruction-right">
                <img src="./assets/icons/instruction.png" alt="img" />
              </div>
            </div>

            {/* <img src="./assets/icons/game-instructions.png" alt="img" /> */}
          </div>
          <div className="example-main-wrap">
            <div className="game-fees-wrap">
              <h3> Game Fees:</h3>
              <p>Certain fees deducted during the game</p>
            </div>
            <div className="game-example-wrap">
              <h4> Example: </h4>
              <div className="example-wrap-repeat">
                <p> Your Bid Amount</p>
                <img
                  className="example-line"
                  src="./assets/icons/example-line.png"
                  alt="line"
                />
                <div className="fess-amount-wrap">
                  <p> 0.001 ETH </p>
                  <img
                    className="example-eth-icon"
                    src="./assets/icons/eth-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
              <div className="example-wrap-repeat">
                <p> Opponent Bid</p>
                <img
                  className="example-line"
                  src="./assets/icons/example-line.png"
                  alt="line"
                />
                <div className="fess-amount-wrap">
                  <p> 0.001 ETH </p>
                  <img
                    className="example-eth-icon"
                    src="./assets/icons/eth-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
              <div className="example-wrap-repeat">
                <p> Fee </p>
                <img
                  className="example-line"
                  src="./assets/icons/example-line.png"
                  alt="line"
                />
                <div className="fess-amount-wrap">
                  <p> 0.0002 ETH </p>

                  <img
                    className="example-eth-icon"
                    src="./assets/icons/eth-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>

            <div className="example-wrap-repeat">
              <p className="winner-get"> You get </p>
              <img
                className="example-line"
                src="./assets/icons/example-blue-line.png"
                alt="line"
              />
              <div className="fess-amount-wrap">
                <p className="winner-price"> 0.0018 ETH </p>

                <img
                  className="example-eth-icon"
                  src="./assets/icons/eth-icon.png"
                  alt="icon"
                />
              </div>
            </div>
            {/* <img src="./assets/icons/game-example.png" alt="img" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionsDrawer;
