// import { createStore, compose } from "redux";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import rootReducer from "./Reducers/rootReducer";

// const initialState = {}; // Initial state of your application

// // Redux persist configuration
// const persistConfig = {
//   key: "root",
//   storage,
// };

// // Wrap the root reducer with persistReducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Enable Redux DevTools Extension
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// // Create the Redux store
// const store = createStore(persistedReducer, initialState, composeEnhancers());

// // Create the persistor for persisting the Redux store
// const persistor = persistStore(store);

// export default store;
// export { persistor };

import { createStore, compose } from "redux";
import rootReducer from "./Reducers/rootReducer";

const initialState = {}; // Initial state of your application

// Enable Redux DevTools Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the Redux store
const store = createStore(rootReducer, initialState, composeEnhancers());

export default store;
