// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import timerSound from "../../Sounds/timer_sound.wav";
// import loader from "../lottie-files/loader.json";
// import Lottie from "lottie-react";
// import { playBtnClickSound } from "../../Sounds/SoundController";
// import { useSelector } from "react-redux";
// import noInternet from "../lottie-files/no-internet.json";
// import { servicePost } from "../../helper/api";
// import { web3 } from "../../constants/constant";
// import AndroidNav from "./AndroidNav";
// import AndroidWonModal from "../Modals/AndroidWonModal";
// import AndroidLoseModal from "../Modals/AndroidLoseModal";

// const rsp = require("../../abis/rsp.json");
// const BOXES = [
//   { id: 1, active: true, src: "../assets/icons/rock.png", move: "rock" },
//   { id: 2, active: false, src: "../assets/icons/scissor.png", move: "scissor" },
//   { id: 3, active: false, src: "../assets/icons/hand.png", move: "paper" },
// ];

// const INTERVAL_DELAY = 1000;

// const AndroidGame = () => {
//   const [gameStarted, setGameStared] = useState(true);
//   const [isLoading, setIsloading] = useState(true);
//   const [isLoseSound, setIsLoseSound] = useState(false);
//   const [isWinSound, setIsWinSound] = useState(false);
//   const [seconds, setSeconds] = useState(11);
//   const [isTimerSound, setIsTimerSound] = useState(false);
//   const [loseModalShow, setLoseModalShow] = useState(false);
//   const [wonModalShow, setWonModalShow] = useState(false);
//   const [boxes, setBoxes] = useState(BOXES);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedGesture, setSelectedGesture] = useState("");
//   const [opponentGesture, setOpponentGester] = useState();
//   const [gameResult, setGameResult] = useState("");
//   const [color, setColor] = useState("");
//   const [opponentData, setOpponentData] = useState();
//   const [player1Data, setplayer1Data] = useState();
//   const [player2Data, setplayer2Data] = useState();

//   // New Code
//   const [playerChoice, setPlayerChoice] = useState(null);
//   const [computerChoice, setComputerChoice] = useState(null);

//   console.log("computerChoice", computerChoice);

//   const navigate = useNavigate();
//   const isSoundOn = useSelector((state) => state.sound.isPlaying);

//   // Check user Online or offline.....
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   useEffect(() => {
//     const handleOnline = () => {
//       setIsOnline(true);
//     };

//     const handleOffline = () => {
//       setIsOnline(false);
//     };
//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   // Count down
//   useEffect(() => {
//     if (seconds > 0) {
//       const interval = setInterval(() => {
//         setSeconds((prevSeconds) => prevSeconds - 1);
//         setIsTimerSound(true);
//       }, 1000);
//       return () => clearInterval(interval);
//     }
//   }, [seconds]);

//   useEffect(() => {
//     if (seconds == 0) {
//       timerAudio.pause();
//     }
//     if (seconds == 5) {
//       setColor("yellow");
//     }

//     if (seconds == 0 && selectedGesture == "") {
//       const randomMove = BOXES[Math.floor(Math.random() * BOXES.length)].move;
//       setPlayerChoice(null); // Reset user's choice
//       setComputerChoice(randomMove);
//       determineWinner(null, randomMove);
//     }
//   }, [seconds]);

//   // Auto add Active Class untill Select any gestured
//   useEffect(() => {
//     if (selectedBox === null) {
//       const interval = setInterval(() => {
//         setBoxes((prevBoxes) =>
//           prevBoxes.map((box, index) => ({
//             ...box,
//             active:
//               index ===
//               (prevBoxes.findIndex((box) => box.active) + 1) % prevBoxes.length,
//           }))
//         );
//       }, INTERVAL_DELAY);
//       return () => clearInterval(interval);
//     } else {
//       setBoxes((prevBoxes) =>
//         prevBoxes.map((box) => ({
//           ...box,
//           active: box.id === selectedBox,
//         }))
//       );
//     }
//   }, [selectedBox]);

//   // Win/lose After 3 sec. Go to Bet page
//   //   useEffect(() => {
//   //     setTimeout(() => {
//   //       setLoseModalShow(false);
//   //       setWonModalShow(false);
//   //       if (loseModalShow || wonModalShow) {
//   //         localStorage.setItem("GameStarted", null);
//   //         navigate("/android-bet");
//   //       }
//   //     }, 3000);
//   //   }, [loseModalShow, wonModalShow]);

//   // Play Slound When Click button
//   const [timerAudio] = useState(new Audio(timerSound));

//   // Play Timer Sound...
//   useEffect(() => {
//     timerAudio.loop = true; // Enable looping
//     if (isTimerSound) {
//       timerAudio.currentTime = 0; // Reset the audio to the beginning
//       if (isSoundOn) {
//         timerAudio.play().catch((error) => {
//           console.log("Autoplay failed:", error);
//         });
//       }
//     } else {
//       timerAudio.pause();
//       timerAudio.currentTime = 0; // Reset the audio to the beginning
//     }

//     return () => {
//       timerAudio.pause();
//       timerAudio.currentTime = 0; // Reset the audio to the beginning
//     };
//   }, [isTimerSound, isSoundOn]);

//   const addMove = async (move) => {
//     try {
//       const accounts = await web3.eth.getAccounts();
//       const moveData = await servicePost("oppenent/addMove", {
//         walletId: accounts[0],
//         move: move,
//       });
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const getOpponent = async () => {
//     try {
//       const accounts = await web3.eth.getAccounts();
//       const response = await servicePost("oppenent/getoppenent", {
//         walletId: accounts[0],
//       });
//       setOpponentData(response.data);
//       setplayer1Data(response.data.player1);

//       setplayer2Data(response.data.player2);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getOpponent();
//   }, [seconds]);

//   // Function to generate computer move
//   const generateComputerMove = () => {
//     const randomIndex = Math.floor(Math.random() * 3);
//     return BOXES[randomIndex].move;
//   };

//   // Handle player choice
//   const handlePlayerChoice = (move) => {
//     setPlayerChoice(move);

//     // Generate computer move
//     const computerMove = generateComputerMove();
//     setComputerChoice(computerMove);

//     // Determine the winner
//     determineWinner(move, computerMove);
//   };

//   // Add Transaction When bet Complete
//   const addTransaction = async () => {
//     try {
//       const accounts = await web3.eth.getAccounts();
//       console.log("accounts", accounts);

//       const transactionRes = await servicePost(
//         "transaction/createTransaction",
//         {
//           gameId: opponentData.gameId,
//           walletId: accounts[0],
//           provider: "metamask",
//           amount: "0.001",
//           currency: "eth",
//           player1: opponentData?.player1,
//           player2: opponentData?.player2,
//           player1move: opponentData?.player1Move,
//           player2move: computerChoice,
//           transactionType: "Bet",
//         }
//       );
//       console.log("transactionRes", transactionRes);
//       setIsloading(false);
//       localStorage.setItem("GameStarted", null);
//       navigate("/android-bet");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // Determine the winner
//   const determineWinner = (playerMove, computerMove) => {
//     if (computerMove == "scissor") {
//       setTimeout(() => {
//         setOpponentGester("../assets/icons/scissor.png");
//       }, 2000);
//     } else if (computerMove == "rock") {
//       setTimeout(() => {
//         setOpponentGester("../assets/icons/rock.png");
//       }, 2000);
//     } else if (computerMove == "paper") {
//       setTimeout(() => {
//         setOpponentGester("../assets/icons/hand.png");
//       }, 2000);
//     }
//     if (!playerMove) {
//       // User didn't select any move within 10 seconds
//       setGameResult("You Lose");
//       setIsloading(true);
//       setTimeout(() => {
//         setLoseModalShow(true);
//         // setIsLoseSound(true);
//         setIsloading(false);
//       }, 5000);
//     } else if (playerMove === computerMove) {
//       setGameResult("Tie");
//       setIsloading(true);
//       setTimeout(() => {
//         setGameResult("");
//         setIsloading(false);
//         setSeconds(11);
//         setSelectedBox(null);
//         setOpponentGester();
//         setSelectedGesture("");
//       }, 5000);
//     } else if (
//       (playerMove === "rock" && computerMove === "scissor") ||
//       (playerMove === "scissor" && computerMove === "paper") ||
//       (playerMove === "paper" && computerMove === "rock")
//     ) {
//       setGameResult(
//         `${capitalizeFirstLetter(playerMove)} crushes ${capitalizeFirstLetter(
//           computerMove
//         )}`
//       );

//       setTimeout(() => {
//         setIsloading(true);
//         setWonModalShow(true);
//         setIsWinSound(true);
//         // hit Add Transaction Api
//         addTransaction();
//         setIsloading(false);
//       }, 5000);
//     } else {
//       setGameResult(
//         `${capitalizeFirstLetter(computerMove)} crushes ${capitalizeFirstLetter(
//           playerMove
//         )}`
//       );

//       setIsloading(true);
//       setTimeout(() => {
//         setLoseModalShow(true);
//         setIsLoseSound(true);
//         // hit Add Transaction Api
//         addTransaction();
//         setIsloading(false);
//       }, 5000);
//     }
//   };

//   // Helper function to capitalize the first letter of a string
//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   };

//   return (
//     <>
//       <AndroidNav />
//       <AndroidWonModal
//         show={wonModalShow}
//         onHide={() => setWonModalShow(false)}
//         isWinSound={isWinSound}
//       />
//       <AndroidLoseModal
//         show={loseModalShow}
//         onHide={() => setLoseModalShow(false)}
//         isLoseSound={isLoseSound}
//       />

//       {/****************** Desktop View  *****/}
//       {isOnline ? (
//         <div className="start-game-main-wrapper desktop-view">
//           <div className="navbar-space"> </div>
//           <div className="bet-price-wrapper">
//             <img src="../assets/icons/gold-medal.png" alt="icon" />
//             <p> 0.002 </p>
//           </div>
//           <div className="start-game-main-wrap">
//             <div className="players-name-wrap">
//               <h3>Opponent </h3>
//               <h3> You </h3>
//             </div>
//             <div className="game-choose-gesture-wrap">
//               <div className="opponent-gesture-wrap">
//                 {opponentGesture ? (
//                   <div className="seleced-gesture-wrap">
//                     <img
//                       className="opponent-choose-gesture"
//                       src={opponentGesture}
//                       alt="img"
//                     />
//                   </div>
//                 ) : selectedGesture ? (
//                   <div className="player-wave-wrap">
//                     <img src="../assets/icons/hand.png" alt="img" />
//                     <p>
//                       Opponent is choosing a <br /> Gesture...
//                     </p>
//                   </div>
//                 ) : (
//                   <p>
//                     Opponent choosing a <br /> Gesture after you select
//                   </p>
//                 )}
//               </div>

//               <div className="choose-guester-center">
//                 {opponentGesture ? (
//                   <div className="result-text">
//                     {gameResult == "" && isLoading ? (
//                       <div className="loading-animation">
//                         <Lottie animationData={loader} loop={true}></Lottie>
//                       </div>
//                     ) : (
//                       <h4> {gameResult} </h4>
//                     )}
//                   </div>
//                 ) : seconds == 11 ? (
//                   <div className="start-game-timmer-wrap">
//                     <h5> START </h5>
//                   </div>
//                 ) : (
//                   <div className="countdown-container">
//                     <h2
//                       className={
//                         seconds === 0
//                           ? `countdown-text ${color} finished`
//                           : `countdown-text ${color}`
//                       }
//                     >
//                       {seconds}
//                     </h2>
//                   </div>
//                 )}
//               </div>

//               <div className="player-gesture-wrap">
//                 {selectedGesture ? (
//                   <div className="seleced-gesture-wrap">
//                     <img src={selectedGesture} alt="img" />
//                   </div>
//                 ) : (
//                   <div className="player-wave-wrap">
//                     <img src="../assets/icons/hand.png" alt="img" />
//                     <p>
//                       To begin the game, <br /> please choose a gesture
//                     </p>
//                   </div>
//                 )}

//                 <div className="choose-gesture-wrapper">
//                   {boxes.map((box) => (
//                     <div
//                       key={box.id}
//                       className={`choose-gesture-option ${
//                         box.active ? "active" : ""
//                       }`}
//                       onClick={() => {
//                         if (!selectedGesture) {
//                           setSelectedBox(box.id);
//                           addMove(box.move);
//                           handlePlayerChoice(box.move);
//                           // transaction();
//                           setSelectedGesture(box.src);
//                           setSeconds(seconds);
//                           playBtnClickSound(isSoundOn);
//                           setColor("");
//                           timerAudio.play();
//                         }
//                       }}
//                     >
//                       <img src={box.src} alt="img" />
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="no-internet-wrapper">
//           <div className="no-internet-wrap">
//             <div className="internet-connection-wrapper">
//               <div className="no-internet-lottie">
//                 <Lottie animationData={noInternet} loop={true}></Lottie>
//               </div>
//               <h1> NO INTERNET</h1>
//             </div>
//           </div>
//         </div>
//       )}

//       {/****************** Mobile View  *****/}

//       {isOnline ? (
//         <div className="start-game-main-wrapper mobile-view">
//           {/* <Navbar isWalletConnect="true" /> */}
//           <div className="navbar-space-start"> </div>
//           <div className="start-game-main-wrap">
//             <div className="players-name-wrap">
//               <h3>Opponent </h3>
//               <div className="bet-price-wrapper">
//                 <img src="../assets/icons/gold-medal.png" alt="icon" />
//                 <p> 0.002 </p>
//               </div>
//             </div>
//             <div className="mobile-game-choose-gesture-wrap">
//               <div className="opponent-gesture-wrap mobile-opponent-gester-wrap">
//                 {opponentGesture ? (
//                   <div className="seleced-gesture-wrap">
//                     <img
//                       className="opponent-choose-gesture"
//                       src={opponentGesture}
//                       alt="img"
//                     />
//                   </div>
//                 ) : selectedGesture ? (
//                   <div className="player-wave-wrap">
//                     <img src="../assets/icons/hand.png" alt="img" />
//                     <p>
//                       Opponent is choosing a <br /> Gesture...
//                     </p>
//                   </div>
//                 ) : (
//                   <p>
//                     Opponent choosing a <br /> Gesture after you select
//                   </p>
//                 )}
//               </div>

//               <div className="mobile-choose-guester-center">
//                 {opponentGesture ? (
//                   <div className="result-text">
//                     {gameResult == "" && isLoading ? (
//                       <div className="loading-animation">
//                         <Lottie animationData={loader} loop={true}></Lottie>
//                       </div>
//                     ) : (
//                       <h4> {gameResult} </h4>
//                     )}
//                   </div>
//                 ) : seconds == 11 ? (
//                   <div className="start-game-timmer-wrap">
//                     <h5> START </h5>
//                   </div>
//                 ) : (
//                   <div className="countdown-container">
//                     <h2
//                       className={
//                         seconds === 0
//                           ? `countdown-text ${color} finished`
//                           : `countdown-text ${color}`
//                       }
//                     >
//                       {seconds}
//                     </h2>
//                   </div>
//                 )}
//               </div>

//               <div className="mobile-player-gesture-wrap">
//                 {selectedGesture ? (
//                   <div className="seleced-gesture-wrap">
//                     <img src={selectedGesture} alt="img" />
//                   </div>
//                 ) : (
//                   <div className="player-wave-wrap">
//                     <img src="../assets/icons/hand.png" alt="img" />
//                     <p>
//                       To begin the game, <br /> please choose a gesture
//                     </p>
//                   </div>
//                 )}

//                 <div className="game-players-name-wrap">
//                   <h3>you </h3>
//                 </div>

//                 <div className="choose-gesture-wrapper">
//                   {boxes.map((box) => (
//                     <div
//                       key={box.id}
//                       className={`choose-gesture-option ${
//                         box.active ? "active" : ""
//                       }`}
//                       onClick={() => {
//                         if (!selectedGesture) {
//                           setSelectedBox(box.id);
//                           setSelectedGesture(box.src);
//                           handlePlayerChoice(box.move);
//                           setSeconds(11);
//                           playBtnClickSound(isSoundOn);
//                           addMove(box.move);
//                           setColor("");
//                           timerAudio.play();
//                         }
//                       }}
//                     >
//                       <img src={box.src} alt="img" />
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="no-internet-wrapper">
//           <div className="no-internet-wrap">
//             <div className="internet-connection-wrapper">
//               <div className="no-internet-lottie">
//                 <Lottie animationData={noInternet} loop={true}></Lottie>
//               </div>
//               <h1> NO INTERNET</h1>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default AndroidGame;
import React, { useEffect, useState } from "react";
import AndroidWonModal from "../Modals/AndroidWonModal";
import AndroidLoseModal from "../Modals/AndroidLoseModal";
import { useNavigate } from "react-router-dom";
import timerSound from "../../Sounds/timer_sound.wav";
import loader from "../lottie-files/loader.json";
import Lottie from "lottie-react";
import { playBtnClickSound } from "../../Sounds/SoundController";
import { useDispatch, useSelector } from "react-redux";
import noInternet from "../lottie-files/no-internet.json";
import { servicePost } from "../../helper/api";
import { web3 } from "../../constants/constant";
import AndroidNav from "./AndroidNav";
import { SetTxDependenc } from "../../redux/Actions/soundActions";

const rsp = require("../../abis/rsp.json");
const BOXES = [
  { id: 1, active: true, src: "../assets/icons/rock.png", move: "rock" },
  { id: 2, active: false, src: "../assets/icons/scissor.png", move: "scissor" },
  { id: 3, active: false, src: "../assets/icons/hand.png", move: "paper" },
];

const INTERVAL_DELAY = 1000;

const AndroidGame = () => {
  const [gameStarted, setGameStared] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [isLoseSound, setIsLoseSound] = useState(false);
  const [isWinSound, setIsWinSound] = useState(false);
  const [seconds, setSeconds] = useState(11);
  const [isTimerSound, setIsTimerSound] = useState(false);
  const [loseModalShow, setLoseModalShow] = useState(false);
  const [wonModalShow, setWonModalShow] = useState(false);
  const [boxes, setBoxes] = useState(BOXES);
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedGesture, setSelectedGesture] = useState("");
  const [opponentGesture, setOpponentGester] = useState();
  const [gameResult, setGameResult] = useState("");
  const [color, setColor] = useState("");
  const [opponentData, setOpponentData] = useState();
  const [player1Data, setplayer1Data] = useState();
  const [player2Data, setplayer2Data] = useState();

  const txDep = useSelector((state) => state.sound.txDep);
  const dispatch = useDispatch();

  // New Code
  const [playerChoice, setPlayerChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);

  const navigate = useNavigate();
  const isSoundOn = useSelector((state) => state.sound.isPlaying);

  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Count down
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setIsTimerSound(true);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  useEffect(() => {
    if (seconds == 0) {
      timerAudio.pause();
    }
    if (seconds == 5) {
      setColor("yellow");
    }

    if (seconds == 0 && selectedGesture == "") {
      const randomMove = BOXES[Math.floor(Math.random() * BOXES.length)].move;
      setPlayerChoice(null); // Reset user's choice
      setComputerChoice(randomMove);
      determineWinner(null, randomMove);
    }
  }, [seconds]);

  // Auto add Active Class untill Select any gestured
  useEffect(() => {
    if (selectedBox === null) {
      const interval = setInterval(() => {
        setBoxes((prevBoxes) =>
          prevBoxes.map((box, index) => ({
            ...box,
            active:
              index ===
              (prevBoxes.findIndex((box) => box.active) + 1) % prevBoxes.length,
          }))
        );
      }, INTERVAL_DELAY);
      return () => clearInterval(interval);
    } else {
      setBoxes((prevBoxes) =>
        prevBoxes.map((box) => ({
          ...box,
          active: box.id === selectedBox,
        }))
      );
    }
  }, [selectedBox]);

  // Win/lose After 3 sec. Go to Bet page
  //   useEffect(() => {
  //     setTimeout(() => {
  //       setLoseModalShow(false);
  //       setWonModalShow(false);
  //       if (loseModalShow || wonModalShow) {
  //         localStorage.setItem("GameStarted", null);
  //         navigate("/android-bet");
  //       }
  //     }, 3000);
  //   }, [loseModalShow, wonModalShow]);

  // Play Slound When Click button
  const [timerAudio] = useState(new Audio(timerSound));

  // Play Timer Sound...
  useEffect(() => {
    timerAudio.loop = true; // Enable looping
    if (isTimerSound) {
      timerAudio.currentTime = 0; // Reset the audio to the beginning
      if (isSoundOn) {
        timerAudio.play().catch((error) => {
          // console.log("Autoplay failed:", error);
        });
      }
    } else {
      timerAudio.pause();
      timerAudio.currentTime = 0; // Reset the audio to the beginning
    }

    return () => {
      timerAudio.pause();
      timerAudio.currentTime = 0; // Reset the audio to the beginning
    };
  }, [isTimerSound, isSoundOn]);

  const addMove = async (move) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const moveData = await servicePost("oppenent/addMove", {
        walletId: accounts[0],
        move: move,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getOpponent = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const response = await servicePost("oppenent/getoppenent", {
        walletId: accounts[0],
      });
      // console.log("response", response);
      setOpponentData(response.data);
      setplayer1Data(response.data.player1);

      setplayer2Data(response.data.player2);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOpponent();
  }, [seconds]);

  // Function to generate computer move
  const generateComputerMove = () => {
    const randomIndex = Math.floor(Math.random() * 3);
    return BOXES[randomIndex].move;
  };

  // Handle player choice
  const handlePlayerChoice = (move) => {
    setPlayerChoice(move);

    // Generate computer move
    const computerMove = generateComputerMove();
    setComputerChoice(computerMove);

    // Determine the winner
    determineWinner(move, computerMove);
  };

  // Add Transaction When bet Complete
  const addTransaction = async (playerMove, computerMove, result) => {
    const accounts = await web3.eth.getAccounts();
    const winner =
      result === "You Won"
        ? accounts[0]
        : result === "You Lose"
        ? "0x415a6B59746EbdA819b3ce74d5715b25E633Ed89"
        : "";

    const transferAmount =
      result === "You Won"
        ? "0.0018"
        : result === "You Lose"
        ? "0.001"
        : result === "Tie"
        ? "0.0001"
        : "0.001";

    setIsloading(true);
    try {
      setIsloading(true);
      const transactionRes = await servicePost(
        "transaction/createnewTransaction",
        {
          gamesId: opponentData.gamesId,
          walletId: accounts[0],
          oppenent: "0x415a6B59746EbdA819b3ce74d5715b25E633Ed89",
          provider: "metamask",
          amount: transferAmount,
          currency: "eth",
          move: playerMove,
          oppenentmove: computerMove,
          winner: winner,
          transactionType: result,
        }
      );

      // console.log("transactionRes", transactionRes);

      if (result === "You Won") {
        setWonModalShow(true);
        setIsWinSound(true);

        setTimeout(() => {
          setIsloading(false);
          localStorage.setItem("GameStarted", null);
          dispatch(
            SetTxDependenc({
              Dep1: txDep + 1,
            })
          );
          navigate("/android-bet");
        }, 5000);
      } else if (result === "You Lose") {
        setLoseModalShow(true);
        setIsLoseSound(true);
        setTimeout(() => {
          setIsloading(false);
          localStorage.setItem("GameStarted", null);
          dispatch(
            SetTxDependenc({
              Dep1: txDep + 1,
            })
          );
          navigate("/android-bet");
        }, 5000);
      }

      setTimeout(() => {
        setIsloading(false);
        localStorage.setItem("GameStarted", null);
        dispatch(
          SetTxDependenc({
            Dep1: txDep + 1,
          })
        );
        navigate("/android-bet");
      }, 5000);
    } catch (error) {
      console.log("error", error);
      setTimeout(() => {
        setIsloading(false);
        alert("Contract under maintenance");
        localStorage.setItem("GameStarted", null);
        navigate("/bet");
      }, 5000);
    }
  };

  const determineWinner = (playerMove, computerMove) => {
    // Update opponent gesture
    setTimeout(() => {
      if (computerMove === "scissor") {
        setOpponentGester("../assets/icons/scissor.png");
      } else if (computerMove === "rock") {
        setOpponentGester("../assets/icons/rock.png");
      } else if (computerMove === "paper") {
        setOpponentGester("../assets/icons/hand.png");
      }
    }, 2000);

    if (!playerMove) {
      // User didn't select any move within 10 seconds
      setGameResult("You Lose");
      setIsloading(true);
      setTimeout(() => {
        // setLoseModalShow(true);
        // setIsLoseSound(true);
        setIsloading(false);
        setGameResult("");

        // hit Add Transaction Api
        addTransaction(playerMove, computerMove, "You Lose");
      }, 5000);
    } else if (playerMove === computerMove) {
      setGameResult("It's a Tie!");
      setIsloading(true);
      setTimeout(() => {
        setIsloading(false);
        setGameResult("");
        // hit Add Transaction Api
        addTransaction(playerMove, computerMove, "Tie");
      }, 5000);
    } else {
      const winningCombinations = {
        rock: { beats: "scissor", message: "Rock crushes Scissor" },
        scissor: { beats: "paper", message: "Scissor cut Paper" },
        paper: { beats: "rock", message: "Paper covers Rock" },
      };

      if (winningCombinations[playerMove].beats === computerMove) {
        setGameResult(winningCombinations[playerMove].message);

        setTimeout(() => {
          setIsloading(true);
          // setWonModalShow(true);
          // setIsWinSound(true);

          setGameResult("");
          // hit Add Transaction Api
          addTransaction(playerMove, computerMove, "You Won");
          setIsloading(false);
        }, 5000);
      } else {
        setGameResult(`${winningCombinations[computerMove].message}`);

        setIsloading(true);
        setTimeout(() => {
          // setLoseModalShow(true);
          // setIsLoseSound(true);
          setGameResult("");
          // hit Add Transaction Api
          addTransaction(playerMove, computerMove, "You Lose");
          setIsloading(false);
        }, 5000);
      }
    }
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <AndroidNav />
      <AndroidWonModal
        show={wonModalShow}
        onHide={() => setWonModalShow(false)}
        isWinSound={isWinSound}
      />
      <AndroidLoseModal
        show={loseModalShow}
        onHide={() => setLoseModalShow(false)}
        isLoseSound={isLoseSound}
      />

      {/****************** Desktop View  *****/}
      {isOnline ? (
        <div className="start-game-main-wrapper desktop-view">
          <div className="navbar-space"> </div>
          <div className="bet-price-wrapper">
            <img src="../assets/icons/gold-medal.png" alt="icon" />
            <p> 0.002 </p>
          </div>
          <div className="start-game-main-wrap">
            <div className="players-name-wrap">
              <h3>Opponent </h3>
              <h3> You </h3>
            </div>
            <div className="game-choose-gesture-wrap">
              <div className="opponent-gesture-wrap">
                {opponentGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img
                      className="opponent-choose-gesture"
                      src={opponentGesture}
                      alt="img"
                    />
                  </div>
                ) : selectedGesture ? (
                  <div className="player-wave-wrap">
                    <img src="../assets/icons/hand.png" alt="img" />
                    <p>
                      Opponent is choosing a <br /> Gesture...
                    </p>
                  </div>
                ) : (
                  <p>
                    Opponent choosing a <br /> Gesture after you select
                  </p>
                )}
              </div>

              <div className="choose-guester-center">
                {opponentGesture ? (
                  <div className="result-text">
                    {gameResult == "" && isLoading ? (
                      <div className="loading-animation">
                        <Lottie animationData={loader} loop={true}></Lottie>
                      </div>
                    ) : (
                      <h4> {gameResult} </h4>
                    )}
                  </div>
                ) : seconds == 11 ? (
                  <div className="start-game-timmer-wrap">
                    <h5> START </h5>
                  </div>
                ) : (
                  <div className="countdown-container">
                    <h2
                      className={
                        seconds === 0
                          ? `countdown-text ${color} finished`
                          : `countdown-text ${color}`
                      }
                    >
                      {seconds}
                    </h2>
                  </div>
                )}
              </div>

              <div className="player-gesture-wrap">
                {selectedGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img src={selectedGesture} alt="img" />
                  </div>
                ) : (
                  <div className="player-wave-wrap">
                    <img src="../assets/icons/hand.png" alt="img" />
                    <p>
                      To begin the game, <br /> please choose a gesture
                    </p>
                  </div>
                )}

                <div className="choose-gesture-wrapper">
                  {boxes.map((box) => (
                    <div
                      key={box.id}
                      className={`choose-gesture-option ${
                        box.active ? "active" : ""
                      }`}
                      onClick={() => {
                        if (!selectedGesture) {
                          setSelectedBox(box.id);
                          addMove(box.move);
                          handlePlayerChoice(box.move);
                          // transaction();
                          setSelectedGesture(box.src);
                          setSeconds(seconds);
                          playBtnClickSound(isSoundOn);
                          setColor("");
                          timerAudio.play();
                        }
                      }}
                    >
                      <img src={box.src} alt="img" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}

      {/****************** Mobile View  *****/}

      {isOnline ? (
        <div className="start-game-main-wrapper mobile-view">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space-start"> </div>
          <div className="start-game-main-wrap">
            <div className="players-name-wrap">
              <h3>Opponent </h3>
              <div className="bet-price-wrapper">
                <img src="../assets/icons/gold-medal.png" alt="icon" />
                <p> 0.002 </p>
              </div>
            </div>
            <div className="mobile-game-choose-gesture-wrap">
              <div className="opponent-gesture-wrap mobile-opponent-gester-wrap">
                {opponentGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img
                      className="opponent-choose-gesture"
                      src={opponentGesture}
                      alt="img"
                    />
                  </div>
                ) : selectedGesture ? (
                  <div className="player-wave-wrap">
                    <img src="../assets/icons/hand.png" alt="img" />
                    <p>
                      Opponent is choosing a <br /> Gesture...
                    </p>
                  </div>
                ) : (
                  <p>
                    Opponent choosing a <br /> Gesture after you select
                  </p>
                )}
              </div>

              <div className="mobile-choose-guester-center">
                {opponentGesture ? (
                  <div className="result-text">
                    {gameResult == "" && isLoading ? (
                      <div className="loading-animation">
                        <Lottie animationData={loader} loop={true}></Lottie>
                      </div>
                    ) : (
                      <h4> {gameResult} </h4>
                    )}
                  </div>
                ) : seconds == 11 ? (
                  <div className="start-game-timmer-wrap">
                    <h5> START </h5>
                  </div>
                ) : (
                  <div className="countdown-container">
                    <h2
                      className={
                        seconds === 0
                          ? `countdown-text ${color} finished`
                          : `countdown-text ${color}`
                      }
                    >
                      {seconds}
                    </h2>
                  </div>
                )}
              </div>

              <div className="mobile-player-gesture-wrap">
                {selectedGesture ? (
                  <div className="seleced-gesture-wrap">
                    <img src={selectedGesture} alt="img" />
                  </div>
                ) : (
                  <div className="player-wave-wrap">
                    <img src="../assets/icons/hand.png" alt="img" />
                    <p>
                      To begin the game, <br /> please choose a gesture
                    </p>
                  </div>
                )}

                <div className="game-players-name-wrap">
                  <h3>you </h3>
                </div>

                <div className="choose-gesture-wrapper">
                  {boxes.map((box) => (
                    <div
                      key={box.id}
                      className={`choose-gesture-option ${
                        box.active ? "active" : ""
                      }`}
                      onClick={() => {
                        if (!selectedGesture) {
                          setSelectedBox(box.id);
                          setSelectedGesture(box.src);
                          handlePlayerChoice(box.move);
                          setSeconds(11);
                          playBtnClickSound(isSoundOn);
                          addMove(box.move);
                          setColor("");
                          timerAudio.play();
                        }
                      }}
                    >
                      <img src={box.src} alt="img" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AndroidGame;
