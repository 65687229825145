// import React, { useState, useEffect } from "react";
// import "../mode/SelectMode.css";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { playBtnClickSound } from "../../Sounds/SoundController";
// import Lottie from "lottie-react";
// import noInternet from "../lottie-files/no-internet.json";
// import { servicePost } from "../../helper/api";
// import loader from "../lottie-files/loader.json";
// import { web3 } from "../../constants/constant";
// import Navbar from "../Navbar";
// import AndroidNav from "./AndroidNav";
// import Web3 from "web3";
// const rsp = require("../../abis/rsp.json");
// const AndroidBet = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const isSoundOn = useSelector((state) => state.sound.isPlaying);
//   // Check user Online or offline.....
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   const [parameterValue, setParameterValue] = useState(null);
//   const [paramValue, setParamValue] = useState("");
//   // const [playerAddress, setPlayerAddress] = useState();

//   const userUuid = window.location.pathname;
//   const userId = userUuid.split("android-bet/");
//   const Uuid = userId[1];

//   // get Path where user want to go
//   const getuserPath = async () => {
//     try {
//       servicePost(`android/loginfrommetabrowser`, {
//         uuid: Uuid,
//       })
//         .then((response) => {
//           console.log("Api response", response);
//           // setPlayerAddress(response.data);
//         })
//         .catch((err) => {});
//     } catch (error) {
//       console.log("err", error);
//     }
//   };

//   useEffect(() => {
//     getuserPath();
//   }, [Uuid]);

//   useEffect(() => {
//     const handleOnline = () => {
//       setIsOnline(true);
//     };

//     const handleOffline = () => {
//       setIsOnline(false);
//     };

//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   const navigate = useNavigate();
//   //   const goBack = () => {
//   //     navigate("/mode");
//   //   };

//   const goToWaitingPage = () => {
//     navigate("/android/opponent-waiting");
//   };
//   ///////////////////
//   // Function to fetch data from the API
//   const fetchDataFromApi = async (gameId) => {
//     try {
//       //const provider = localStorage.getItem("wallet");
//       const response = await servicePost("oppenent/gamesId", {
//         gameId: gameId,
//       });
//       console.log("response", response);
//       //const data = await response.json();
//       console.log("data.data.gamesId", response.data.gamesId);
//       var gamessId = response.data.gamesId;
//       console.log("gamessId", gamessId);
//       return gamessId;
//       // Assuming the API response contains a parameter named 'parameterValue'
//       // setParameterValue(prevValue =>gamessId);
//       // setParamValue(prevValue =>gamessId);
//       // console.log("setParameterValue=",parameterValue);
//       // console.log("setParamValue=",paramValue);
//     } catch (error) {
//       console.error("Error fetching data from the API:", error);
//     }
//   };
//   ////////////

//   // Recursive function to check the parameter value and call the API again if necessary
//   const checkParameterValue = async (currentValue, gameId) => {
//     if (currentValue == null) {
//       // Wait for 1 second before making the next API call
//       console.log("currentValue", currentValue);
//       console.log("gameId", gameId);
//       const value = await fetchDataFromApi(gameId);
//       if (value != null) {
//         setParameterValue(value);
//         setParamValue(value);
//         console.log("setParameterValue=", parameterValue);
//         console.log("setParamValue=", paramValue);
//       }
//       checkParameterValue(parameterValue, gameId); // Call the function again with the latest parameterValue
//     } else {
//       setTimeout(checkParameterValue(currentValue, gameId), 6000);
//       //alert("done");
//     }
//   };

//   // Create Game
//   const createGame = async () => {
//     const playerAddress = localStorage.getItem("wallet");

//     var gameId;
//     const rspAbi = rsp.abi;
//     const contract = new web3.eth.Contract(
//       rspAbi,
//       "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
//     );
//     // playerBalance
//     let cont2 = await contract.methods.playerBalance(playerAddress).call();
//     console.log("cont2", cont2);
//     let balances = web3.utils.fromWei(cont2, "ether");

//     if (balances < 0.001) {
//       alert("Balance is low,please deposit balance to RSP");
//       return;
//     }

//     const createGame = await servicePost("oppenent/addOppenent", {
//       walletId: playerAddress,
//       provider: "metamask",
//       amount: "0.001",
//     });
//     try {
//       console.log("createGame", createGame);
//       setIsLoading(true);
//       if (createGame) {
//         let txhash;
//         console.log("h1");
//         const ether = web3.utils.toWei("0.001", "ether");
//         console.log("ether", ether);
//         gameId = createGame.data.gameId;
//         const rspAbi = rsp.abi; //0x1a3fbDd6aa84f20C13029F7A1feB71a5e92Aa2D5
//         if (playerAddress == createGame.data.player1) {
//           console.log("player1"); //0xB3f85A6BbED416E559A0a1b0a6bA3f6808d9f433
//           const contract = new web3.eth.Contract(
//             rspAbi,
//             "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
//           );
//           let startnewgame = await contract.methods
//             .AddPlayer1(gameId)
//             .send({ from: playerAddress, gas: 200000 })
//             .then(async (val) => {
//               //console.log("val=",val);
//               txhash = val.transactionHash;
//               console.log("val.transactionHash=", val);
//               console.log("gameId=", val.events.gameInfo.returnValues.gameId);
//               const gamesId = val.events.gameInfo.returnValues.gameId;
//               alert(txhash);
//               const updateGame = await servicePost("oppenent/updategame", {
//                 gameId: createGame.data.gameId,
//                 gamesId: gamesId,
//               });
//             });
//           setIsLoading(false);
//           goToWaitingPage();
//         } else if (playerAddress == createGame.data.player2) {
//           console.log("player2");
//           // const gamesId=createGame.data.gamesId;
//           // if(gamesId==null){
//           //   if (parameterValue == null) {
//           //   const gameId=createGame.data.gameId;

//           // const value=  await fetchDataFromApi(gameId);
//           // if (value == null) {
//           //   // If the received value is not true, recursively call the handleSubmit again
//           //   setTimeout(handleSubmit, 3000);
//           // } else {
//           //   setParameterValue(value); // Update the state when the desired value is received
//           //  }
//           //  checkParameterValue(parameterValue,gameId);
//           //    }else{
//           //            const contract = new web3.eth.Contract(rspAbi, "0xdD742E09CFEceda3B14caB2d6A0d3c1Ba50123ec");
//           //         let startnewgame = await contract.methods.AddPlayer2(ether,parameterValue).send({ from: provider,gas: 100000})
//           //         .then(async (val) => {
//           //         //console.log("val=",val);
//           //           txhash=val.transactionHash
//           //           console.log("val.transactionHash=",val);
//           //           console.log("gameId=",val.events.gameInfo.returnValues.gameId);

//           //           alert(txhash);
//           //         });
//           //         setIsLoading(false);
//           //     goToWaitingPage();
//           //             }

//           //       const checkParameterValue = async (parameterValue) => {
//           //
//           //           console.log("parameterValue=",parameterValue);
//           //           await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 1 second before making the next API call
//           //           const gameId=createGame.data.gameId;
//           //           await fetchDataFromApi(gameId);
//           //           checkParameterValue(parameterValue); // Call the function again
//           //         }else{

//           //           const contract = new web3.eth.Contract(rspAbi, "0xB3f85A6BbED416E559A0a1b0a6bA3f6808d9f433");
//           //     let startnewgame = await contract.methods.AddPlayer2(ether,parameterValue).send({ from: provider,gas: 100000})
//           //     .then(async (val) => {
//           //     //console.log("val=",val);
//           //       txhash=val.transactionHash
//           //       console.log("val.transactionHash=",val);
//           //       console.log("gameId=",val.events.gameInfo.returnValues.gameId);

//           //       alert(txhash);
//           //     });
//           //     setIsLoading(false);
//           // goToWaitingPage();
//           //         }
//           //       };

//           //  }else{
//           const gammesId = createGame.data.gamesId;
//           alert("gammesId", gammesId);
//           const contract = new web3.eth.Contract(
//             rspAbi,
//             "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
//           );
//           let startnewgame = await contract.methods
//             .AddPlayer2(gammesId)
//             .send({ from: playerAddress, gas: 200000 })
//             .then(async (val) => {
//               //console.log("val=",val);
//               txhash = val.transactionHash;
//               console.log("val.transactionHash=", val);
//               console.log("gameId=", val.events.gameInfo.returnValues.gameId);

//               alert(txhash);
//               const updateGame = await servicePost(
//                 "oppenent/updateopponentStatus",
//                 {
//                   gameId: createGame.data.gameId,
//                 }
//               );
//             });
//           setIsLoading(false);
//           goToWaitingPage();
//           // }
//         }
//       }
//     } catch (err) {
//       setIsLoading(false);
//       console.log("err", err.code);
//       if (err.code == 4001) {
//         console.log("createGame.data.gameId", createGame.data.gameId);
//         const updateGame = await servicePost("oppenent/updategameStatus", {
//           gameId: createGame.data.gameId,
//         });
//       }
//       if (err.response?.data.message == "Player already added in game") {
//         goToWaitingPage();
//       }
//     }
//   };
//   useEffect(() => {
//     //checkParameterValue(null,null);
//     // Runs ONCE after initial rendering
//   }, []);
//   return (
//     <>
//       <AndroidNav />
//       {isOnline ? (
//         <div className="splash-main-wrapper">
//           {/* <Navbar isWalletConnect="true" /> */}
//           <div className="navbar-space"> </div>
//           <div className="mode-select-wrapper">
//             <a
//               href="intent://142.132.224.108:3002/#Intent;scheme=https;package=com.example.gyantek_rps;end"
//               target="_new"
//             >
//               <img
//                 //   onClick={goBack}
//                 className="goback-mode-btn"
//                 src="../assets/icons/back-white.png"
//                 alt="icon"
//               />
//             </a>
//             ;
//             <div className="mode-select-wrap">
//               <h2>BET</h2>

//               <div className="bet-price-box">
//                 <img src="../assets/icons/bet-box.png" alt="img" />
//               </div>

//               <div className="place-bet-wrapper">
//                 <div className="place-bet-text-wrap">
//                   <img src="../assets/icons/white-shield.png" alt="icon" />
//                   <h4> This Bet Amount is Fix for Every player </h4>
//                 </div>

//                 {isLoading ? (
//                   <button disabled>
//                     <div className="tranfer-button-loading">
//                       <Lottie animationData={loader} loop={true}></Lottie>
//                     </div>
//                   </button>
//                 ) : (
//                   <button
//                     onClick={() => {
//                       playBtnClickSound(isSoundOn);
//                       createGame();
//                     }}
//                   >
//                     Place a Bet
//                   </button>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="no-internet-wrapper">
//           <div className="no-internet-wrap">
//             <div className="internet-connection-wrapper">
//               <div className="no-internet-lottie">
//                 <Lottie animationData={noInternet} loop={true}></Lottie>
//               </div>
//               <h1> NO INTERNET</h1>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default AndroidBet;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { playBtnClickSound } from "../../Sounds/SoundController";
import Lottie from "lottie-react";
import noInternet from "../lottie-files/no-internet.json";
import { servicePost } from "../../helper/api";
import loader from "../lottie-files/loader.json";
import { web3 } from "../../constants/constant";
import AndroidNav from "./AndroidNav";
const rsp = require("../../abis/rsp.json");
const AndroidBet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isSoundOn = useSelector((state) => state.sound.isPlaying);
  // Check user Online or offline.....
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [parameterValue, setParameterValue] = useState(null);
  const [paramValue, setParamValue] = useState("");
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const navigate = useNavigate();
  const goToWaitingPage = () => {
    navigate("/android/opponent-waiting");
  };
  ///////////////////
  // Function to fetch data from the API
  const fetchDataFromApi = async (gameId) => {
    try {
      //const provider = localStorage.getItem("wallet");
      const response = await servicePost("oppenent/gamesId", {
        gameId: gameId,
      });
      console.log("response", response);
      //const data = await response.json();
      console.log("data.data.gamesId", response.data.gamesId);
      var gamessId = response.data.gamesId;
      console.log("gamessId", gamessId);
      return gamessId;
      // Assuming the API response contains a parameter named 'parameterValue'
      // setParameterValue(prevValue =>gamessId);
      // setParamValue(prevValue =>gamessId);
      // console.log("setParameterValue=",parameterValue);
      // console.log("setParamValue=",paramValue);
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };
  ////////////

  // Recursive function to check the parameter value and call the API again if necessary
  const checkParameterValue = async (currentValue, gameId) => {
    if (currentValue == null) {
      // Wait for 1 second before making the next API call
      console.log("currentValue", currentValue);
      console.log("gameId", gameId);
      const value = await fetchDataFromApi(gameId);
      if (value != null) {
        setParameterValue(value);
        setParamValue(value);
        console.log("setParameterValue=", parameterValue);
        console.log("setParamValue=", paramValue);
      }
      checkParameterValue(parameterValue, gameId); // Call the function again with the latest parameterValue
    } else {
      setTimeout(checkParameterValue(currentValue, gameId), 6000);
      //alert("done");
    }
  };

  // Create Game
  const createGame = async () => {
    // call contract
    const provider = localStorage.getItem("wallet");
    console.log("provider", provider);
    const rspAbi = rsp.abi;
    const contract = new web3.eth.Contract(
      rspAbi,
      "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
    );

    // check Player balance > 0.001
    let cont2 = await contract.methods.playerBalance(provider).call();
    console.log("cont2", cont2);
    let balances = web3.utils.fromWei(cont2, "ether");

    if (balances < 0.001) {
      alert("Balance is low,please deposit balance to RSP");
      return;
    }

    try {
      setIsLoading(true);
      let txhash;
      const ether = web3.utils.toWei("0.001", "ether");
      console.log("ether", ether);
      const rspAbi = rsp.abi; //0x1a3fbDd6aa84f20C13029F7A1feB71a5e92Aa2D5
      const contract = new web3.eth.Contract(
        rspAbi,
        "0x0FF8bE48db602AbFC49A72BdAd741C908D5fd660"
      );
      let startnewgame = await contract.methods
        .newGame(ether)
        .send({ from: provider, gas: 200000 })
        .then(async (val) => {
          //console.log("val=",val);
          txhash = val.transactionHash;
          // console.log("val.transactionHash=", val);
          // console.log("gameId=", val.events.gameInfo.returnValues.gameId);
          const gamesId = val.events.gameInfo.returnValues.gameId;

          // Call Create Game Api
          const createdGameRes = await servicePost("oppenent/startgame", {
            walletId: provider,
            provider: "metamask",
            amount: "0.001",
            player2: "0x415a6B59746EbdA819b3ce74d5715b25E633Ed89",
            gamesId: gamesId,
            transactionHash: txhash,
          });

          // console.log("createdGameRes", createdGameRes);
        });

      // console.log("startnewgame", startnewgame);
      setIsLoading(false);
      goToWaitingPage();
    } catch (err) {
      setIsLoading(false);
      // console.log("err", err);
    }
  };

  useEffect(() => {
    //checkParameterValue(null,null);
    // Runs ONCE after initial rendering
  }, []);
  return (
    <>
      <AndroidNav />
      {isOnline ? (
        <div className="splash-main-wrapper">
          {/* <Navbar isWalletConnect="true" /> */}
          <div className="navbar-space"> </div>
          <div className="mode-select-wrapper">
            <a
              href="intent://142.132.224.108:3002/#Intent;scheme=https;package=com.example.gyantek_rps;end"
              target="_new"
            >
              <img
                className="goback-mode-btn"
                src="./assets/icons/back-white.png"
                alt="icon"
              />
            </a>
            <div className="mode-select-wrap">
              <h2>BET</h2>

              <div className="bet-price-box">
                <img src="./assets/icons/bet-box.png" alt="img" />
              </div>

              <div className="place-bet-wrapper">
                <div className="place-bet-text-wrap">
                  <img src="./assets/icons/white-shield.png" alt="icon" />
                  <h4> This Bet Amount is Fix for Every player </h4>
                </div>

                {isLoading ? (
                  <button disabled>
                    <div className="tranfer-button-loading">
                      <Lottie animationData={loader} loop={true}></Lottie>
                    </div>
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={() => {
                      playBtnClickSound(isSoundOn);
                      createGame();
                    }}
                  >
                    Place a Bet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-internet-wrapper">
          <div className="no-internet-wrap">
            <div className="internet-connection-wrapper">
              <div className="no-internet-lottie">
                <Lottie animationData={noInternet} loop={true}></Lottie>
              </div>
              <h1> NO INTERNET</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AndroidBet;
