import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import wonAnimation from "../lottie-files/won.json";
import winSound from "../../Sounds/win_sound.wav";

const WonModal = (props) => {
  const [audio] = useState(new Audio(winSound));

  useEffect(() => {
    if (props.isWinSound) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [props.isWinSound, audio]);
  return (
    <>
      {props.show && <div className="blur-background"></div>}
      <Modal
        {...props}
        size="lg"
        className="won-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="won-modal-wrapper">
            <div className="won-animation-wrap">
              <Lottie animationData={wonAnimation} loop={true}></Lottie>
            </div>
            <div className="won-main-contant-wrap">
              <img src="./assets/icons/won.png" alt="icon" />
              <h2>YOU Won</h2>
              <div className="lose-amount-wrap won-amount-wrap">
                <h4> 0.0018 </h4>
                <img src="./assets/icons/eth-icon.png" alt="icon" />
              </div>

              <div className="won-price-detail">
                <div className="price-detail-wrap">
                  <h3> Your Bid : </h3>
                  <h3> 0.001 ETH</h3>
                </div>
                <div className="price-detail-wrap">
                  <h3> Opponent Bid </h3>
                  <h3> 0.001 ETH</h3>
                </div>
                <div className="price-detail-wrap">
                  <h3> Fees: </h3>
                  <h3> 0.0002 ETH</h3>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WonModal;
