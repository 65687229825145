import clickSound from "./sound.wav";
export const playBtnClickSound = (isSoundOn) => {
  const audio = new Audio(clickSound);

  if (isSoundOn) {
    audio.play().catch((error) => {
      console.log("Autoplay failed:", error);
    });
  }
};
